import React from 'react';
import API from '../API';
import SearchCoreModal, { SearchCoreProps } from './SearchCoreModal';
import { BankBin } from '../AdminClasses';

var SearchPromoterModal = (props: SearchCoreProps<BankBin>)=>{
	return <SearchCoreModal
		title='Buscar banco'
		{...props}
		searchData={(query, page, page_size)=>{
			return API.getBankBinList(query, page * page_size, page_size);
		}}
		pageSize={15}
		table={[
			{ name: 'ID', key: 'bank_id', collapsing: true },
			{ name: 'Banco', key: 'bank_name' },
			{ name: 'Descripcion', key: 'description' },
		]}
	/>
}

export default SearchPromoterModal;