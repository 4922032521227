import React, { useState, useEffect } from "react";
import { Header } from 'react-frontier';
import { useParams } from "react-router-dom";
import { Zone } from "@arema/components/Classes";
import { NotFound, SidebarContainer } from "../../components";
import API from "../../API";
import ZoneCreate from "./ZoneCreate";
import ZoneEvents from "./ZoneEvents";

var ZoneContainer = ()=>{
   var params = useParams<{ id: string }>();
   var [zoneError, setZoneError] = useState<string>(null);
   var [zone, setZone] = useState<Zone>(null);

   useEffect(()=>{
      if(!zone && !Number.isNaN(parseInt(params.id))){
         API.getZone(parseInt(params.id)).then(res=>{
            if(res.error) return setZoneError(res.message);
            setZone(res.data);
         }).catch(err=>{
            return setZoneError('Hubo un error inesperado cargando los datos de la zona (LCL-1)');
         });
      }
   }, []);

   if(Number.isNaN(parseInt(params.id))){
      return <NotFound />
   }
   if(zoneError){
      return <Header text="Error" subtext={zoneError} iconName='face-frown-open' />
   } else if(!zone){
      return <Header loading text="Cargando Zonas" size={'small'} loaderSize="large" />
   }

   var onEditZone = (zone: Zone)=>{
      setZone(zone);
   }

   return <SidebarContainer
      header={'Zona'}
      routes={[
         { url: `/zones/${params.id}/events`, component: ZoneEvents },
         { url: `/zones/${params.id}`, render: <ZoneCreate key={'zone-edit'} zone={zone} zone_id={params.id} /> },
      ]}
      routeParams={{
         zone,
         zone_id: params.id,
         onEditZone,
      }}
      getTitle={()=>{
         return `Zona ${zone.zone_name}`
      }}
      items={[
         { text: 'Detalles', icon: 'edit', url: `/zones/${params.id}` },
         { text: 'Eventos', icon: 'theater masks', url: `/zones/${params.id}/events` },
      ]}
      contentHeader={(<Header text={zone.zone_name} subtext={'Zona'} style={{ marginTop: 0, paddingTop: 0 }} />)}
   />
}

export default ZoneContainer;