import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Groupper, Header, Icon, Input, Message, Table, Toolbar } from "react-frontier";
import { SetLoading } from "@arema/components/Classes";
import { bindClose, bindFormChange } from "@arema/components/Util";
import { DeletedBanner, NotFound } from "../../components";
import Toast from 'react-hot-toast';
import API from "../../API";
import Validator from "@arema/components/Validator";
import { useUser } from "../../AdminHooks";
import { useTitle } from "@arema/components/Hooks";
import { BankBin } from "../../AdminClasses";
import { Modal } from "semantic-ui-react";
import UserAccess from "../../UserAccess";

enum Modals {
	ADD_BIN_NUMBER = 100,
	DELETE_BIN_NUMBER = 200,
	CLEAR_BIN_NUMBERS = 300,
}

const BankBinCreate = () => {
	var { hasAccess } = useUser();
	var { bank_id } = useParams();
	var { setTitle } = useTitle();
	var navigate = useNavigate()
	var IS_CREATE = bank_id === 'create';

	var [bankBin, setBankBin] = useState<BankBin>(null);
	var [binNumber, setBinNumber] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [shownModal, setShownModal] = useState<Modals>(null);
	var [selectedNumberId, setSelectedNumberId] = useState<number>(null);

	useEffect(() => {
		var id = parseInt(bank_id);
		if (!isNaN(id)) {
			if (bankBin === null || bankBin.bank_id !== id) {
				setBankBin(null);
				loadData(id);
			}
		} else {
			setTitle('Crear Banco');
			setBankBin({
				bank_name: '',
				description: '',
			})
		}
	}, [bank_id]);

	var loadData = async (bank_id: number) => {
		API.getBank(bank_id).then(res => {
			if (res.error) return setErrorPrompts([res.message]);
			setTitle(`Banco ${res.data.bank_name}`);
			setBankBin({
				bank_name: res.data.bank_name,
				description: res.data.description,
				bank_id: res.data.bank_id,
				bank_bin_numbers: res.data.bank_bin_numbers,
			})
		}).catch(err => {
			setErrorPrompts(['Hubo un error cargando el banco (LCL-1)']);
		});
	}

	const submit = async (setLoading: SetLoading) => {
		var body: BankBin = {
			bank_id: null,
			bank_name: bankBin.bank_name,
			description: bankBin.description
		}
		var { valid, prompts } = Validator(body, {
			bank_name: [{
				rule: 'length', params: [4, 64], label: 'Nombre'
			}],
			description: [{
				rule: 'length', params: [4], label: 'Descripcion'
			}],
		});
		setErrorPrompts(prompts);
		if (!valid) return;

		if (!IS_CREATE) {
			body.bank_id = parseInt(bank_id);
		}

		setLoading(true);
		if (IS_CREATE) {
			API.createBankBin(body.bank_name, body.description).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha creado el banco');
				navigate(`/banks/${res.data.bank_id}`);
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado creando el banco (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.editBankBin(body.bank_id, body.bank_name, body.description).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha editando el banco.');
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado editando el banco (LCL-11)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	const saveBins = (setLoading: SetLoading) => {
		if (hasAccess([UserAccess.CATALOGS.BANK_BIN_CREATE, UserAccess.CATALOGS.BANK_BIN_EDIT])) {
			return Toast.error('Usuario no tiene acceso')
		}
		var saveBins = binNumber.split(/\r?\n|\r|\n/g)
			.filter(s => s !== '')
			.map(s => s.trimEnd().trimStart());
		if (saveBins.length === 0) {
			return Toast.error('No hay informacion')
		}
		for (const bin of saveBins) {
			var { valid } = Validator({ bin }, {
				bin: [{ rule: /[0-9]{6,8}/ }]
			});
			if (!valid) return Toast.error('Datos invalidos');

			// verifica si ya existe este bin
			valid = !bankBin.bank_bin_numbers.some(b => b.bin_number === bin);
			if (!valid) return Toast.error(`Ya existe '${bin}'`);
		}
		setLoading(true);
		API.saveBinNumbers(bankBin.bank_id, saveBins).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se han guardado los bines correctamente.');
			setShownModal(null);
			setBankBin(b => {
				var newbins = res.data
				b.bank_bin_numbers.push(...newbins) 
				return b;
			});
			setBinNumber('');
		}).catch(err => {
			Toast.error('Datos invalidos');
		}).finally(() => {
			setLoading(false);
		});
	}

	const deleteBin = (setLoading: SetLoading) => {
		setLoading(true);
		API.deleteBinNumber(selectedNumberId).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se ha eliminado correctamente.');
			setShownModal(null);
			setSelectedNumberId(null);
			setBankBin(b => {
				b.bank_bin_numbers = b.bank_bin_numbers.filter(b => b.number_id !== selectedNumberId);
				return b;
			})
		}).catch(err => {
			Toast.error('Error inesperado (LCL-12)');
		}).finally(() => {
			setLoading(false);
		});

	}

	const clearBines = (setLoading: SetLoading) => {
		setLoading(true);
		API.clearBinNumbers(bankBin.bank_id).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se han eliminado los bines correctamente.');
			setShownModal(null);
			setBankBin(b => {
				b.bank_bin_numbers = [];
				return b;
			})
		}).catch(err => {
			Toast.error('Error inesperado (LCL-13)');
		}).finally(() => {
			setLoading(false);
		});
	}

	const onClickDeleteBin = (number_id: number) => {
		return () => {
			setSelectedNumberId(number_id)
			setShownModal(Modals.DELETE_BIN_NUMBER)
		}
	}

	if (!IS_CREATE && Number.isNaN(parseInt(bank_id))) {
		return <NotFound />
	}

	if (!IS_CREATE && !bankBin) {
		return <Header text="Cargando banco" loading />
	}

	const onChangeField = bindFormChange(bankBin, setBankBin);

	return (
		<div>
			{!!bankBin?.deleted ? <DeletedBanner date_deleted={bankBin.date_deleted} /> : null}
			{bankBin &&
				<Groupper title={IS_CREATE ? 'Crear Banco' : `Editar Banco`} width={500} actions={(
					<Button text="Guardar" color="black" onClick={submit} />
				)}>
					<Input
						label="Nombre"
						placeholder="Nombre del Banco"
						value={bankBin.bank_name}
						onChange={onChangeField('bank_name')}
					/>

					<Input
						label="Descripcion"
						placeholder="Descripcion"
						value={bankBin.description}
						onChange={onChangeField('description')}
					/>

					<Message list={errorPrompts} type="error" style={{ marginTop: 15 }} />
				</Groupper>
			}

			{(!IS_CREATE && bankBin) ? (
				<Groupper
					title='Numero de Bines'
					width={500}
					style={{ width: 500, margin: 'auto', marginTop: 20, }}
				>
					<Toolbar fitted>
						{hasAccess(UserAccess.CATALOGS.BANK_BIN_NUMBER_EDIT) && <Toolbar.Item text='Agregar Bines' icon='plus-circle' onClick={bindClose(setShownModal, Modals.ADD_BIN_NUMBER)} />}
						{hasAccess(UserAccess.CATALOGS.BANK_BIN_NUMBER_EDIT) && bankBin?.bank_bin_numbers.length>0 && <Toolbar.Item text='Eliminar Todo' icon='trash' onClick={bindClose(setShownModal, Modals.CLEAR_BIN_NUMBERS)} />}
					</Toolbar>
					<Table
						fitted
						striped
						className='first'
						collapsingIndexes={[0, 2]}
						centeredIndexes={[2]}
						headers={['ID', 'Numero Bin', <Icon name="remove" />]}
						empty={bankBin.bank_bin_numbers?.length === 0}
						emptyText='No hay numeros de bin'
						data={bankBin?.bank_bin_numbers?.map(a => ([
							a.number_id,
							a.bin_number,
							<Button
								iconName='remove'
								color='black'
								size='tiny'
								style={{ minWidth: 40 }}
								onClick={onClickDeleteBin(a.number_id)}
							/>
						]))}
					/>
				</Groupper>) : null
			}

			<Modal open={shownModal === Modals.ADD_BIN_NUMBER} size='mini' onClose={bindClose(setShownModal)}>
				<Modal.Header>Numero Bin</Modal.Header>
				<Modal.Content>
					<Input
						textarea
						label="Numero"
						placeholder="Bin"
						value={binNumber}
						onChange={setBinNumber}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button text='Cerrar' onClick={bindClose(setShownModal)} />
					<Button text='Guardar' color="black" onClick={saveBins} />
				</Modal.Actions>
			</Modal>

			<Modal open={shownModal === Modals.DELETE_BIN_NUMBER} onClose={bindClose(setShownModal)} size="mini">
				<Modal.Header>Eliminar Numero Bin</Modal.Header>
				<Modal.Content>
					<Header size='small' text='¿Eliminar Numero Bin?' />
					<ul style={{ paddingLeft: 15 }}>
						<li>Una vez eliminado, no podrá ser recuperado</li>
					</ul>
				</Modal.Content>
				<Modal.Actions>
					<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
					<Button text='Eliminar' color={'red'} onClick={deleteBin} />
				</Modal.Actions>
			</Modal>

			<Modal open={shownModal === Modals.CLEAR_BIN_NUMBERS} onClose={bindClose(setShownModal)} size="mini">
				<Modal.Header>Eliminar Todos los Bines</Modal.Header>
				<Modal.Content>
					<Header size='small' text='¿Eliminar Todos los Bines?' />
					<ul style={{ paddingLeft: 15 }}>
						<li>Una vez eliminados, no podrá ser recuperados</li>
					</ul>
				</Modal.Content>
				<Modal.Actions>
					<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
					<Button text='Eliminar' color={'red'} onClick={clearBines} />
				</Modal.Actions>
			</Modal>

		</div>
	)
}

export default BankBinCreate;