import { USER_ADMIN, VENUE, DELIVERY, PROMOTER, EVENTS, PDV, SCANNER, COUPON, TICKET_TEMPLATE, EVENT_GROUPS, USER_EXTERNAL, ZONES, BANK_BIN } from './screens/CatalogList';
import { Navigate, Outlet, createBrowserRouter, useRouteError } from 'react-router-dom';
import { useUser } from './AdminHooks';
import SeatmapEdit from './screens/venue/section/SeatmapEdit';
import UserContainer from './screens/user/UserContainer';
import Navigation from './Navigation';
import {
	Catalog,
	EventList,
	EventCreate,
	EventContainer,
	ChangePassword,
	OrderList,
	VenueCreate,
	OrderView,
	Conciliacion,
	RefundableRequest,
	DeliveryCreate,
	PromoterContainer,
	PdvContainer,
	PdvCreate,
	Scanner,
	PayoutList,
	PayoutView,
	Coupon,
	RefundList, 
	RefundView,
	TicketCreator,
	EventGroupCreate,
	TestScreen,
	UserExternalCreate,
	Papeleta,
	PapeletaDay,
	ConciliacionEventMethod,
	ReportsContainer,
	DatePayments,
	DatePaymentsList,
	ZoneContainer,
	ZoneCreate,
	BankBinCreate,
} from './screens';

const RouterComponent = ()=>{
	var { user, hasAccess } = useUser();

	var ErrorElement = ()=>{
		const error = (useRouteError() as any);
		return <div>
			<div className="fr centered header" style={{ fontSize: 100 }}>
				{error.status}
				<div className="sub header" style={{ fontSize: 20 }}>
					{error.status===404 ? 'Página no encontrada' : (
						hasAccess(6000) ? error.message : 'Hubo un error inesperado.'
					)}
				</div>
			</div>
		</div>
	}

	const Router = createBrowserRouter([{
		path: '/',
		errorElement: <Navigation outlet={<ErrorElement />} />,
		element: <Navigation outlet={<Outlet />} />,
		children: [
			{ path: '/', element: <Catalog options={EVENTS(user, hasAccess)} /> }, 
			{ path: '/auth', element: <Navigate to={'/'} /> },
			// === EVENTS ===	
			{ path: '/events', element: <Catalog options={EVENTS(user, hasAccess)} /> },
			{ path: '/events/create', element: <EventCreate /> },
			{ path: '/events/refundable/:id', element: <RefundableRequest /> },
			{ path: '/events/:id*', element: <EventContainer /> },
			// === ADMINS ===
			{ path: '/admins', element: <Catalog options={USER_ADMIN(user, hasAccess)} /> },
			{ path: '/admins/:id*', element: <UserContainer /> },
			// === PROFILE ===
			{ path: '/profile/password', element:  <ChangePassword /> },
			// === ORDERS ===
			{ path: '/orders', element: <OrderList /> },
			{ path: '/orders/:hash', element: <OrderView /> },
			// === VENUE ===
			{ path: '/venues', element: <Catalog options={VENUE(user, hasAccess)} /> },
			{ path: '/venues/:venue_id', element: <VenueCreate /> },
			{ path: '/venues/:venue_id/sections/:section_id/numerate', element: <SeatmapEdit /> },
			// === CONCILIACION ===
			{ path: '/conciliacion', element: <Conciliacion /> },
			{ path: '/conciliacion/papeleta', element: <Papeleta /> },
			{ path: '/conciliacion/papeleta/:day', element: <PapeletaDay /> },
			{ path: '/conciliacion/events/:event_id/:method_id', element: <ConciliacionEventMethod /> },
			{ path: '/conciliacion/tabla', element: <DatePaymentsList /> },
			{ path: '/conciliacion/tabla/:day', element: <DatePayments /> },
			// === DELIVERY METHODS ===
			{ path: '/c/delivery', element: <Catalog options={DELIVERY(user, hasAccess)} /> },
			{ path: '/c/delivery/:delivery_id', element: <DeliveryCreate /> },
			// === PROMOTERS ===
			{ path: '/promoters', element: <Catalog options={PROMOTER(user, hasAccess)} /> },
			{ path: '/promoters/:id*', element: <PromoterContainer /> },
			// === PDV ===
			{ path: '/pdv', element: <Catalog options={PDV(user, hasAccess)} /> },
			{ path: '/pdv/create', element: <PdvCreate /> },
			{ path: '/pdv/:id*', element: <PdvContainer /> },
			// === SCANNER ===
			{ path: '/scanners', element: <Catalog options={SCANNER(user, hasAccess)} /> },
			{ path: '/scanners/:scanner_id', element: <Scanner /> },
			// === PAYOUTS ===
			{ path: '/payouts', element: <PayoutList /> },
			{ path: '/payouts/:id', element: <PayoutView /> },
			// === COUPONS ===
			{ path: '/c/coupons', element: <Catalog options={COUPON(user, hasAccess)} /> },
			{ path: '/c/coupons/:coupon_id', element: <Coupon /> },
			// === REFOUND ===
			{ path: '/refunds', element: <RefundList /> },
			{ path: '/refunds/:id', element: <RefundView /> },
			// === TEMPLATES ===
			{ path: '/templates', element: <Catalog options={TICKET_TEMPLATE(user, hasAccess)} /> },
			{ path: '/templates/creator', element: <TicketCreator create /> },
			// === EVENT GROUPS ===
			{ path: '/parent', element: <Catalog options={EVENT_GROUPS(user, hasAccess)} /> },
			{ path: '/parent/:group_id', element: <EventGroupCreate /> },
			// === USER EXTERNAL ===
			{ path: 'c/partners', element: <Catalog options={USER_EXTERNAL(user, hasAccess)} /> },
			{ path: 'c/partners/:external_id', element: <UserExternalCreate /> },
			// === INVOICE ===
			// { path: '/invoice', element: <Catalog options={INVOICE(user, hasAccess)} /> },
			// === ZONES ===
			{ path: '/zones', element: <Catalog options={ZONES(user, hasAccess)} /> },
			{ path: '/zones/create', element: <ZoneCreate /> },
			{ path: '/zones/:id*', element: <ZoneContainer /> },
			// === BANKS ===
			{ path: '/banks', element: <Catalog options={BANK_BIN(user, hasAccess)} /> },
			{ path: '/banks/:bank_id', element: <BankBinCreate /> },

			{ path: '/reportes*', element: <ReportsContainer /> },
		]
	}, {
		path: '/test', 
		element: <TestScreen />
	}]);

	return Router;
}

export default RouterComponent;