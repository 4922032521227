import React, { useEffect, useState } from 'react'
import API from '../../API';
import { Header, Pagination, Table } from 'react-frontier';
import { Link } from 'react-router-dom';
import { ErrorHeader } from '../../components';
import moment from 'moment';

interface EventZone { 
	event_id: number,
	event_name: string,
	promoter_name: string,
	venue_name: string,
	date: number,
}

function ZoneEvents(props: { zone_id: number }) {
	var [events, setEvents] = useState<EventZone[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [page, setPage] = useState<number>(0);
	const PAGE_SIZE = 15;

	useEffect(() => {
		if (props.zone_id && !isNaN(props.zone_id)) {
			loadData(page)
		}
	}, [props.zone_id])

	var loadData = (page?: number) => {
		setEvents(null);
		API.getZoneEventsList(props.zone_id, page * PAGE_SIZE, PAGE_SIZE).then(res => {
			if (res.error) return setLoadError(res.message);
			setEvents(res.data);
		}).catch(err => {
			setLoadError('Error inesperado cargando los eventos (LCL-1)');
		})
	}

	var onPageChange = (page: number) => {
		setPage(page);
		loadData(page)
	}

	if(loadError){
		return <ErrorHeader error={loadError} />
	}
	if (!events) return <Header />

	var hasNext = events?.length >= PAGE_SIZE;
	return (
		<div>
			<Table
				striped
				title='Eventos'
				headers={['Id', 'Evento', 'Promotor', 'Recinto', 'Siguiente Fecha']}
				collapsingIndexes={[0, 4]}
				style={{ maxWidth: '100%', minWidth: 700, width: 700, margin: 'auto' }}
				empty={events.length == 0}
				emptyText='No hay eventos en esta zona'
				footer={(<Table.Row>
					<Table.Cell colSpan={5} >
						<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} >
							<Pagination page={page} onPageChange={onPageChange} hasNext={hasNext} />
						</div>
					</Table.Cell>
				</Table.Row>)}
			>
				{events.map(e => (
					<Table.Row
						key={e.event_id}
						collapsingIndexes={[0, 4]}
						compact
						selectable
						as={Link}
						to={`/events/${e.event_id}`}
						data={[
							e.event_id,
							e.event_name,
							e.promoter_name,
							e.venue_name,
							moment.unix(e.date).format('DD/MM/YY HH:mm:ss')
						]}
					/>
				))}
			</Table>
		</div>
	)
}

export default ZoneEvents;
