import React, { useState, useEffect } from "react";
import { Button, Field, Groupper, Input, Message } from 'react-frontier';
import { Dropdown } from "semantic-ui-react";
import { bindSemantic } from "@arema/components/Util";
import { useNavigate } from "react-router-dom";
import { CityVenues, EventSectionProps, Venue } from "@arema/components/Classes";
import API from "../../API";
import Validator from "@arema/components/Validator";
import Toast from "react-hot-toast";
import moment from "moment";

var EventContainer = (props: EventSectionProps)=>{
	var navigate = useNavigate();
	var [cities, setCities] = useState<CityVenues[]>(null);
	var [selectedCity, setSelectedCity] = useState<string>(null);
	var [cityVenuesList, setCityVenuesList] = useState<{ city: string, venues: Venue[] }[]>([]);
	var [cityVenues, setCityVenues] = useState<Venue[]>(null);
	var [cityError, setCityError] = useState<string>(null);
	var [selectedVenue, setSelectedVenue] = useState<number>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [date, setDate] = useState<number>(null);
	
	useEffect(()=>{
		if(date===null){
			var new_date = moment();
			new_date.set('h', 12);
			new_date.set('minute', 0);
			setDate(new_date.unix());
		}
		if(!cities){
			API.getEventsQL(['cities']).then(res=>{
				if(res.error){
					Toast.error(res.message);
					return navigate(-1);
				}
				setCities((res.data.cities as CityVenues[]));
			}).catch(err=>{
				Toast.error('Hubo un error inesperado cargando la información de recintos. (LCL-1)');
				return navigate(-1);
			});
		}
	}, []);

	var onCitySelected = (v: string)=>{
		setSelectedCity(v);
		setSelectedVenue(null);
		var cv = cityVenuesList.find(a=>a.city==v)
		if(cv){
			return setCityVenues(cv.venues);
		}
		setCityVenues(null);
		API.getCityVenues(v).then(res=>{
			if(res.error) return setCityError(res.message);
			var cvn = [...cityVenuesList];
			cvn.push({ city: v, venues: res.data });
			setCityVenuesList(cvn);
			setCityVenues(res.data);
		}).catch(err=>{
			setCityError('Hubo un error cargnado los recintos. (LCL-1)');
		})
	}

	var submit = (setLoading: (b: boolean)=>void)=>{
		var { valid, prompts, form } = Validator({ venue_id: selectedVenue, date }, {
			venue_id: [
				{ rule: 'number', prompt: 'Favor de seleccionar el recinto' }
			],
			date: [
				{ rule: 'number', prompt: 'Favor de seleccionar la fecha del calendario' },
			],
		});

		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createDate(props.event.event_id, form.date, form.venue_id).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			if(res.data.date_id) navigate(`/events/${props.event.event_id}/dates/${res.data.date_id}`, {
				replace: true
			});
			else setErrorPrompts(['Hubo un error inesperado creando el calendario (LCL-3)']);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado creando el calendario (LCL-2)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	return <Groupper title="Crear calendario" width={600} actions={(
		<Button text="Crear calendario" color="black" onClick={submit} />
	)}>
		<Input label="Fecha" placeholder="Fecha del calendario" value={date} onChange={setDate} inputStyle={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }} calendar={{
			date: date || moment().unix(),
			mode: 'datetime'
		}} />
		<Field label="Ciudad">
			<Dropdown selection placeholder="Selecciona la ciudad" search loading={!cities} disabled={!cities} value={selectedCity} onChange={bindSemantic(onCitySelected)} options={cities ? cities.map(a=>({
				value: a.city,
				text: a.city,
				description: `${a.venues} recinto${a.venues==1 ? '' : 's'}`,
			})) : []} />
		</Field>
		{cityError ? (
			<Message text={cityError} type="error" />
		) : (selectedCity) ? (
			<Field label="Recinto">
				<Dropdown search selection placeholder="Selecciona el recinto" onChange={bindSemantic(setSelectedVenue)} value={selectedVenue} loading={!cityVenues} options={cityVenues ? cityVenues.map(a=>({
					value: a.venue_id,
					text: a.venue_name,
					description: `ID: ${a.venue_id}`
				})) : []} />
			</Field>
		) : null}
		<Message list={errorPrompts} type="error" />
	</Groupper>
}

export default EventContainer;