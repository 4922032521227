import React, { useEffect, useState } from "react";
import API from "../../API";
import { Button, Groupper, Header, Input, Message } from 'react-frontier';
import { Checkbox, Modal, Table } from "semantic-ui-react";
import { bindClose, bindFormChange } from "@arema/components/Util";
import { ShortUserAdmin, EventSectionProps, SetLoading } from "@arema/components/Classes";
import { SearchAdminModal, EventPaymentPlanModal, SearchBankModal } from '../../components';
import { useUser } from "../../AdminHooks";
import Validator from "@arema/components/Validator";
import Toast from 'react-hot-toast';
import moment from "moment";
import UserAccess from '../../UserAccess';
import { BankBin } from "../../AdminClasses";

enum MODAL {
	ADMIN_MODAL = 101,
	EDIT_PAYMENT_PLAN = 102,
	BANK_MODAL = 103,
	CONFIRM_DELETE_BANK = 104,
}

var EventContainer = (props: EventSectionProps) => {
	var { hasAccess, user } = useUser();
	var [options, setOptions] = useState({ ...props.event });
	var [submitPrompts, setSubmitPrompts] = useState<string[]>(null)
	var [shownModal, setShownModal] = useState<MODAL>(null);

	var submit = (setLoading: SetLoading) => {
		var { valid, prompts } = Validator(options, {
			subdomain: [
				{ rule: 'length', params: [3, 16], prompt: 'El subdominio debe ser entre 3 y 16 caracteres.', skipEmpty: true },
				{ rule: /^(?<s>[a-z0-9]{3,32})$/gi, prompt: 'El subdominio no es válido', skipEmpty: true },
			],
			access_code: [
				{ rule: 'minLength', params: [3], prompt: 'El código de acceso debe ser entre 3 y 16 caracteres.', skipEmpty: true },
				{ rule: 'maxLength', params: [32], prompt: 'El código de acceso debe ser entre 3 y 16 caracteres.', skipEmpty: true },
			],
			/* commission_return: [
				{ rule: 'number', prompt: 'El retorno debe ser un numero mayor a cero.', skipEmpty: true },
				{ rule: 'min', params: [0] }
			], */
		});
		setSubmitPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		const commission_return = (options.commission_return === null || `${options.commission_return}`.trim() === '') ? null : (options.commission_return / 100);
		API.setEventOptions(props.event.event_id, {
			subdomain: options.subdomain,
			access_code: options.access_code,
			published: options.published,
			meta_pixel: options.meta_pixel,
			google_tag: options.google_tag,
			tiktok_pixel: options.tiktok_pixel,
			date_publish: options.date_publish,
			commission_return: commission_return,
			date_start: options.date_start,
		}).then(res => {
			if (res.error) return setSubmitPrompts([res.message]);
			Toast.success('Se han guardado las opciones del evento.');
			props.onEditEvent(options);
		}).catch(err => {
			return setSubmitPrompts(['Hubo un error inesperado guardando las opciones del evento.']);
		}).finally(() => {
			setLoading(false);
		});
	}

	var onAdminChange = (admin: ShortUserAdmin, setLoading: SetLoading) => {
		if (admin.admin_id !== props.event.user_assigned) {
			setLoading(true);
			API.setEventUserAssigned(props.event.event_id, admin.admin_id).then(res => {
				if (res.error) return Toast.error('Hubo un error inesperado (ADMN-01)');
				Toast.success('Se asigno correctamente el usuario');
				setOptions({
					...props.event,
					user_assigned: admin.admin_id,
					user_assigned_name: `${admin.first_name} ${admin.last_name}`,
					user_assigned_username: admin.username,
				});
				setShownModal(null);
			}).catch(err => {
				Toast.success('Error inesperado (LCL-1)');
			}).finally(() => {
				setLoading(false);
			});
		} else {
			Toast.error('El usuario seleccionado ya es el responsable actual del evento.');
		}
	}

	var onBankChange = (bank: BankBin, setLoading: SetLoading) => {
		if (props.event.bank_id === bank.bank_id) return Toast.error('El banco ya se encuentra seleccionado')
		setLoading(true);
		API.setEventBank(props.event.event_id, bank.bank_id).then(res => {
			if (res.error) return Toast.error('Hubo un error inesperado (BNK-01)');
			Toast.success('Se asigno correctamente el banco');
			setOptions({
				...props.event,
				bank_id: bank.bank_id,
				bank_name: bank.bank_name,
			});
			setShownModal(null);
		}).catch(err => {
			Toast.success('Error inesperado (LCL-2)');
		}).finally(() => {
			setLoading(false);
		});
	}

	var onBankRemove = (setLoading: SetLoading) => {
		setLoading(true);
		API.setEventBank(props.event.event_id, null).then(res => {
			if (res.error) return Toast.error('Hubo un error inesperado (BNK-02)');
			Toast.success('Se eliminó el banco de este evento');
			setOptions({
				...props.event,
				bank_id: null,
				bank_name: '',
			});
			setShownModal(null);
		}).catch(err => {
			Toast.success('Error inesperado (LCL-3)');
		}).finally(() => {
			setLoading(false);
		});
	}

	var onFormChange = bindFormChange(options, setOptions)

	return <>
		<Groupper title="Opciones" width={600} actions={(
			<Button text="Guardar" color="black" onClick={submit} />
		)}>
			{hasAccess(1002) && (
				<div className="fr option">
					<div className="info">
						<div className="title">Publicar</div>
						<div className="description">Permitir acceso y venta del evento</div>
					</div>
					<Checkbox toggle checked={!!options.published} onChange={onFormChange('published', true)} />
				</div>
			)}
			<div className="fr option">
				<div className="info">
					<div className="title">Fecha de publicación</div>
					<div className="description">El evento no se mostrará hasta esta fecha.</div>
				</div>
				<Input 
					placeholder="Fecha de publicación"
					value={options.date_publish} 
					onChange={onFormChange('date_publish')} 
					calendar={{ date: options.date_publish || moment().unix(), mode: 'date' }}
					button={!options.date_publish ? null : (
						<Button 
							icon 
							iconName="remove" 
							style={{ minWidth: 0, paddingRight: 20 }} 
							iconStyle={{ margin: 0 }} 
							onClick={() => onFormChange('date_publish', false, false)(null) } 
						/>)
					}
				/>
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Retorno</div>
					<div className="description">Comision de Retorno.</div>
				</div>
				<Input placeholder="Retorno" value={options.commission_return} onChange={onFormChange('commission_return')} />
			</div>
			{hasAccess(1100) && (
				<div className="fr option">
					<div className="info">
						<div className="title">Subdominio</div>
						<div className="description">Accesa al evento mediante el subdominio.</div>
					</div>
					<Input placeholder="Subdominio" value={options.subdomain} onChange={onFormChange('subdomain')} />
				</div>
			)}
			<div className="fr option">
				<div className="info">
					<div className="title">Tiktok pixel</div>
					<div className="description">Solo el identificador del pixel. Debe de estar en la sección que dice: ttq.load('IDENTIFICADOR DEL PIXEL') 
						<div>Si son multiples pixeles, separarlos con un ;</div>
					</div>
				</div>
				<Input placeholder="Pixel" value={options.tiktok_pixel} onChange={onFormChange('tiktok_pixel')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Facebook pixel</div>
					<div className="description">Solo el identificador del pixel. Debe de estar en la sección que dice: fbq('init', 'IDENTIFICADOR DEL PIXEL')</div>
				</div>
				<Input placeholder="Pixel" value={options.meta_pixel} onChange={onFormChange('meta_pixel')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Google Tag</div>
					<div className="description">Google Tag Manager (GTM-*)</div>
				</div>
				<Input placeholder="Google Tag" value={options.google_tag} onChange={onFormChange('google_tag')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Cuenta Regresiva</div>
					<div className="description">Muestra un contador de fecha de lanzamiento del evento</div>
				</div>
				<Input calendar={{
					date: options.date_start || moment().unix(),
					minDate: moment().unix()
				}} placeholder="Cuenta Regresiva" value={options.date_start} onChange={onFormChange('date_start')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Responsable del Evento</div>
					<div className="description">Usuario Responsable del evento</div>
				</div>
				<Input placeholder="Responsable" readonly value={options.user_assigned_username?.toUpperCase()} button={(
					(hasAccess(UserAccess.EVENTS.EDIT_USER_ASSIGNED) || user.admin_id === options.user_assigned) ?
						<Button style={{ minWidth: 0, paddingRight: 20 }} icon iconStyle={{ margin: 0 }} iconName="pen" onClick={bindClose(setShownModal, MODAL.ADMIN_MODAL)} />
						: null
				)} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Código de acceso</div>
					<div className="description">Limitar el acceso al evento para que requiera un código para poder ver el evento.</div>
				</div>
				<Input placeholder="Código de acceso" value={options.access_code} onChange={onFormChange('access_code')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Banco Exclusivo</div>
					<div className="description">Banco exclusivo del evento</div>
				</div>
				<Input
					placeholder="Banco"
					readonly
					value={options.bank_name?.toUpperCase()}
					button={((hasAccess(UserAccess.EVENTS.EDIT_BANK)) ? (options.bank_id === null ? (
						<Button style={{ minWidth: 0, paddingRight: 20 }} icon iconStyle={{ margin: 0 }} iconName="pen" onClick={bindClose(setShownModal, MODAL.BANK_MODAL)} />)
						: (<Button style={{ minWidth: 0, paddingRight: 20 }} icon iconStyle={{ margin: 0 }} iconName="remove" onClick={bindClose(setShownModal, MODAL.CONFIRM_DELETE_BANK)} />)
					) : null
					)}
				/>
			</div>
			<div className="fr last option">
				<div className="info">
					<div className="title">Planes de Pago</div>
					<div className="description">Planes de pago del evento</div>
				</div>
				<Button
					icon
					iconRightStyle={{ width: 35 }}
					iconRight="pen"
					size="small"
					text="Editar planes de pago"
					onClick={bindClose(setShownModal, MODAL.EDIT_PAYMENT_PLAN)}
				/>
			</div>
			<Message list={submitPrompts} type="error" style={{ marginTop: 15 }} />
		</Groupper>

		<EventPaymentPlanModal
			open={shownModal === MODAL.EDIT_PAYMENT_PLAN}
			onClose={bindClose(setShownModal)}
			title="Editar planes de pago"
			event_id={props.event.event_id}
		/>

		<SearchAdminModal
			open={shownModal === MODAL.ADMIN_MODAL}
			onClose={bindClose(setShownModal)}
			onSelected={onAdminChange}
		/>

		<SearchBankModal
			open={shownModal === MODAL.BANK_MODAL}
			onClose={bindClose(setShownModal)}
			onSelected={onBankChange}
		/>

		<Modal open={shownModal === MODAL.CONFIRM_DELETE_BANK} size='tiny' onClose={bindClose(setShownModal)}>
			<Modal.Header>Esta seguro de eliminar el banco</Modal.Header>
			<Modal.Content>
				<Header text='¿Eliminar Banco?' style={{ marginBottom: 20 }} />
				<Message type='info' centered>
					Se eliminará el banco de este evento
				</Message>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
				<Button text='Continuar' color='black' onClick={onBankRemove} />
			</Modal.Actions>
		</Modal>
	</>
}

export default EventContainer;