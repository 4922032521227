import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Dropdown, Icon, Menu, Modal, Popup } from 'semantic-ui-react';
import { Button, Field, Groupper, Header, Input, Table } from 'react-frontier';
import { SeatStatus, VenueSeat, VenueSection } from '@arema/components/Classes';
import { bindClose, bindFormChange, formatSeatNumber, letterToNumber, numberToLetter } from '@arema/components/Util';
import { DEBUG } from '../../../AdminConfig';
import { useTitle } from '@arema/components/Hooks';
import API from '../../../API';
import classNames from 'classnames';
import Toast from 'react-hot-toast';

import '@arema/components/style/seatmap.scss';
import '../../../style/seatmap_edit.scss';
import { Seatmap } from '@arema/components';

interface SeatmapSeat extends VenueSeat{
	dirty?: boolean,
	deleted: boolean
}

interface SelectionCoordinates{
	ended: boolean,
	start_x: number,
	start_y: number,
	end_x: number,
	end_y: number,
}

interface SelectionCoordinateBounds extends SelectionCoordinates{
	width: number,
	height: number,
}

interface SelectionBounds{
	start_x: number,
	start_y: number,
	end_x: number,
	end_y: number,
	width: number,
	height: number,
}

interface Seatmap{
	dirty: boolean,
	seats_width: number,
	seats_height: number,
	stage_x: number,
	stage_y: number,
	stage_width: number,
	stage_height: number,
}

enum ModalType{
	NUMBER = 1,
	SECTION = 2,
	DELETE_SEATS = 3,
	SEAT_COMMENT = 4,
	SEAT_LABEL = 5,
}
enum SeatConfigType {
	NUMBERS = 1,
	LETTERS = 2,
}
enum SeatDirection {
	RIGHT_LEFT = 'rtl',
	LEFT_RIGHT = 'ltr',
	TOP_BOTTOM = 'ttb',
	BOTTOM_TOP = 'btt',
}

interface SeatConfig {
	type?: SeatConfigType,
	direction?: SeatDirection,
	initial_value?: string,
	skip_empty?: boolean,
}

interface SingleConfig{
	row: string,
	number: string
}

const SEAT_WIDTH = 70, SEAT_HEIGHT = 45, SEAT_SPACING = 4;
const PREVIEW_SIZE = 3;
const SEAT_STATUS_LOCALE = [
	{ id: SeatStatus.FREE, 			name: 'Libre', 		class: '',				color: '#e8e8e8', },
	{ id: SeatStatus.BLOCKED,	 	name: 'Bloqueado',	class: 'blocked', 	color: 'brown', },
	{ id: SeatStatus.LOCKED,	 	name: 'Candado', 		class: 'locked',		color: 'gray', },
	{ id: SeatStatus.HIDDEN, 		name: 'Escondido', 	class: 'hidden',		color: 'transparent', }
]

var SeatmapEdit = ()=>{
	let { venue_id, section_id } = useParams();
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [section, setSection] = useState<VenueSection>(null);
	var [seats, setSeats] = useState<SeatmapSeat[]>(null);
	var [seatmap, setSeatmap] = useState<Seatmap>(null);
	var [seatSelection, setSeatSelection] = useState<SelectionCoordinates[]>(null);
	var [configRows, setConfigRows] = useState<SeatConfig>(null);
	var [configCols, setConfigCols] = useState<SeatConfig>(null);
	var [singleConfig, setSingleConfig] = useState<SingleConfig>(null);
	var [shownModal, setShownModal] = useState<ModalType>(null);
	var [sectionName, setSectionName] = useState<string>('');
	var [contextMenu, setContextMenu] = useState<{ x: number, y: number, left: boolean, bottom: boolean }>(null);
	var [sending, setSending] = useState<boolean>(false);
	var [deleteConfirm, setDeleteConfirm] = useState<string>('');
	var [seatComment, setSeatComment] = useState<string>('');
	var [seatStatus, setSeatStatusM] = useState<number>(null);
	var [seatSize, setSeatSize] = useState<number>(1);
	var firstSeatRef = useRef(null);

	useEffect(()=>{
		if(!seats && !Number.isNaN(parseInt(venue_id)) && !Number.isNaN(parseInt(section_id))){
			API.getVenueSection(parseInt(venue_id), parseInt(section_id), true).then(res=>{
				if(res.error) return setLoadError(res.message);
				var seats = [...res.data.seats];
				delete res.data.seats;
				setSection(res.data);
				setTitle(`Numeración: ${res.data.section_name}`);
				setSeatmap({
					dirty: false,
					seats_width: res.data.seats_width,
					seats_height: res.data.seats_height,
					stage_x: res.data.stage_x,
					stage_y: res.data.stage_y,
					stage_width: res.data.stage_width,
					stage_height: res.data.stage_height,
				});
				setSeats(seats.map(a=>({
					...a,
					deleted: false,
				})));
			}).catch(err=>{
				setLoadError('Hubo un error inesperado cargando el mapa de butacas. (LCL-1)');
			});
		}
	}, []);

	var isSelected = (x: number, y: number)=>{
		if(!seatSelection || seatSelection.length==0) return false;
		for(var i of seatSelection){
			var start_x = Math.min(i.start_x, i.end_x), end_x = Math.max(i.start_x, i.end_x);
			var start_y = Math.min(i.start_y, i.end_y), end_y = Math.max(i.start_y, i.end_y);
			if(x>=start_x && y>=start_y && x<=end_x && y<=end_y) return true;
		}
		return false;
	}

	var getSelectedSeats = ()=>{
		if(!seatSelection || seatSelection.length==0) return []
		return seats.filter(a=>isSelected(a.seat_x, a.seat_y) && !a.deleted);
	}

	var getSelectionCoords = () : SelectionCoordinateBounds[]=>{
		if(!seatSelection) return null;
		var coords : SelectionCoordinateBounds[] = [];
		for(var i of seatSelection){
			var start_x = Math.min(i.start_x, i.end_x), end_x = Math.max(i.start_x, i.end_x);
			var start_y = Math.min(i.start_y, i.end_y), end_y = Math.max(i.start_y, i.end_y);
			coords.push({
				ended: i.ended,
				start_x, start_y,
				end_x, end_y,
				width: end_x-start_x+1,
				height: end_y-start_y+1,
			});
		}
		return coords;
	}

	var coordsToBounds = (coords: SelectionCoordinates[])=>{
		var bounds : SelectionBounds = {
			start_x: 1000000,	start_y: 1000000,
			end_x: -1, 		end_y: -1,
			width: null, 	height: null
		}
		for(var i of coords){
			bounds.start_x = Math.min(bounds.start_x, i.start_x);
			bounds.start_y = Math.min(bounds.start_y, i.start_y);
			bounds.end_x = Math.max(bounds.end_x, i.end_x);
			bounds.end_y = Math.max(bounds.end_y, i.end_y);
		}
		bounds.width = bounds.end_x-bounds.start_x+1;
		bounds.height = bounds.end_y-bounds.start_y+1;
		return bounds;
	}

	var getSelectionBounds = () : SelectionBounds=>{
		if(!seatSelection || seatSelection.length==0) return null;
		return coordsToBounds(getSelectionCoords());
	}

	var getSeatSize = (size: number)=>{
		var seat_width = SEAT_WIDTH*size;
		var seat_height = SEAT_HEIGHT*size;
		var seat_spacing = SEAT_SPACING*size;
		var seat_style : React.CSSProperties = {
			width: seat_width,
			height: seat_height,
			maxWidth: seat_width,
			maxHeight: seat_height,
			minWidth: seat_width,
			minHeight: seat_height,
			margin: seat_spacing/2,
		}

		return { seat_width, seat_height, seat_spacing, seat_style };
	}

	var has_selection = useMemo(()=>{
		return !!seatSelection && seatSelection.length>0;
	}, [seatSelection]);
	var real_selection = useMemo(()=>{
		return getSelectionCoords();
	}, [seatSelection]);
	var selection_bounds = useMemo(()=>{
		return getSelectionBounds();
	}, [seatSelection]);
	var seats_selected = useMemo(()=>{
		return getSelectedSeats();
	}, [seatSelection, seats]);
	var stage_dirty = useMemo(()=>{
		return (seats && seats.filter(a=>a.dirty || !a.seat_id).length>0) || seatmap?.dirty
	}, [seats, seatmap]);
	var stage_selected = useMemo(()=>{
		if(!real_selection || real_selection.length==0 || !seatmap) return false;
		var has_stage = seatmap && seatmap.stage_height>0 && seatmap.stage_width>0 && seatmap.stage_x!==null && seatmap.stage_y!==null;
		if(!has_stage) return false;
		for(var i of real_selection){
			if(seatmap.stage_x===i.start_x && seatmap.stage_y===i.start_y && seatmap.stage_width===i.width && seatmap.stage_height===i.height){
				return true;
			}
		}
		return false;
	}, [real_selection, seatmap]);
	var effective_seat_size = useMemo(()=>{
		return getSeatSize(seatSize || 1);
	}, [seatSize]);

	if(Number.isNaN(parseInt(venue_id)) || Number.isNaN(parseInt(section_id))){
		return <Header text='404' subtext='No se encontró el mapa del recinto.' />
	}
	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}
	if(seats===null){
		return <Header loading text='Cargando butacas...' />
	}

	var getSeatsClone = ()=>{
		return seats.map(a=>({ ...a }));
	}

	var getSeatCoordsFromCoords = (x: number, y: number)=>{
		var { x: smX, y: smY } = firstSeatRef.current?.getBoundingClientRect();
		var { seat_width, seat_height, seat_spacing } = effective_seat_size;

		var seat_x = Math.floor((x-smX)/(seat_width+seat_spacing));
		var seat_y = Math.floor((y-smY)/(seat_height+seat_spacing));
		if(seat_x<0 || seat_y<0) return null;
		if(seat_x>=seatmap.seats_width || seat_y>=seatmap.seats_height) return null;
		seat_x = seat_x >= seatmap.seats_width ? (seatmap.seats_width-1) : seat_x;
		seat_y = seat_y >= seatmap.seats_height ? (seatmap.seats_height-1) : seat_y;
		return {
			x: seat_x,
			y: seat_y,
		}
	}

	var getActiveSelection = ()=>{
		if(!seatSelection || seatSelection.length==0) return { index: -1, selection: null };
		var ix = seatSelection.findIndex(a=>!a.ended);
		return {
			index: ix,
			selection: ix==-1 ? null : seatSelection[ix]
		}
	}

	var moveSelection = (ix: number, end_x: number, end_y: number)=>{
		var sel = [...seatSelection];
		sel[ix].end_x = end_x;
		sel[ix].end_y = end_y;
		setSeatSelection(sel);
	}

	var endSelection = ()=>{
		var ix = seatSelection ? seatSelection.findIndex(a=>!a.ended) : -1;
		var sel = [...(seatSelection || [])];
		if(ix!=-1){
			sel[ix].ended = true;
		}
		setSeatSelection(sel);
	}

	var startSelection = (seat_x: number, seat_y: number, empty: boolean=true, ended: boolean=false)=>{
		var sel = [];
		if(!empty && seatSelection){
			sel.push(...seatSelection);
		}
		sel.push({
			ended,
			start_x: seat_x, start_y: seat_y,
			end_x: seat_x, end_y: seat_y,
		});
		setSeatSelection(sel);
	}

	var onSelectStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
		setContextMenu(null);
		var seat = getSeatCoordsFromCoords(e.clientX, e.clientY);
		if(e.button==2){
			if(!isSelected(seat.x, seat.y)){
				startSelection(seat.x, seat.y, !(e.metaKey || e.shiftKey), true);
			}
			return;
		}else if(e.button!==0) return;
		if(!seat) return setSeatSelection([]);
		startSelection(seat.x, seat.y, !(e.metaKey || e.shiftKey));
	}

	var onSelectMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
		var { index, selection } = getActiveSelection();
		if(!selection || index==-1) return;
		var seat = getSeatCoordsFromCoords(e.clientX, e.clientY);

		if(seat && (selection.end_x!=seat.x || selection.end_y!=seat.y)){
			moveSelection(index, seat.x, seat.y);
		}
	}

	var onSelectEnd = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
		endSelection()
		// if(!selection || selection.ended) return;
		// if(selection.end_x<0 || selection.end_y<0) return setSelection(null);
		// setSelection({
		// 	...selection,
		// 	ended: true
		// })
	}

	const CONTEXT_MENU_HEIGHT = 5*40; // Number of items * 40
	var onContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
		e.preventDefault();
		var seat = getSeatCoordsFromCoords(e.clientX, e.clientY);
		var left = seat.x<(seatmap.seats_width/2);
		var bottom = seat.y<(seatmap.seats_height/2);
		setContextMenu({ x: e.clientX-(!left ? 200 : 0), y: e.clientY-(!bottom ? CONTEXT_MENU_HEIGHT : 0), left, bottom });
	}

	var isStageSelected = ()=>{
		for(let c of getSelectionCoords()){
			for(var x=c.start_x; x<c.end_x+1; x++){
				for(var y=c.start_y; y<c.end_y+1; y++){
					if(isStage(x, y)) return true;
				}
			}
		}
		return false;
	}

	var isStage = (x: number, y: number)=>{
		return !!seatmap.stage_width && (seatmap.stage_x<=x && (seatmap.stage_x+seatmap.stage_width)>x) && (seatmap.stage_y<=y && (seatmap.stage_y+seatmap.stage_height)>y);
	}

	var getSelectedSeatsIndex = ()=>{
		var indexes : number[] = [];
		for(var i=0; i<seats.length; i++){
			if(!seats[i].deleted && isSelected(seats[i].seat_x, seats[i].seat_y)){
				indexes.push(i);
			}
		}
		return indexes;
	}

	var fixStartEnd = (start: number, end: number)=>{
		return {
			start: Math.min(start, end),
			end: Math.max(start, end),
		}
	}

	var updateMapColumns = (amount: number)=>{
		if(sending) return;
		var max_x = Math.max(...seats.map(a=>a.seat_x), seatmap.stage_x+seatmap.stage_width-1);
		if((seatmap.seats_width+amount)<=max_x){
			return Toast.error('No se puede hacer mas pequeño el mapa de butacas.')
		}

		setSeatmap({
			...seatmap,
			dirty: true,
			seats_width: seatmap.seats_width+amount,
		})
	}

	var updateMapRows = (amount: number)=>{
		if(sending) return;
		var max_y = Math.max(...seats.map(a=>a.seat_y), seatmap.stage_y+seatmap.stage_height-1);
		if((seatmap.seats_height+amount)<=max_y){
			return Toast.error('No se puede hacer mas pequeño el mapa de butacas.')
		}

		setSeatmap({
			...seatmap,
			dirty: true,
			seats_height: seatmap.seats_height+amount,
		})
	}

	var checkValidType = (val: string, type: SeatConfigType) => {
		if (!val || val.length === 0) return true;
		if (type === SeatConfigType.NUMBERS) {
			return /[0-9]+/.test(val);
		} else if (type === SeatConfigType.LETTERS) {
			return /[a-z]+/i.test(val);
		} else return true;
	}

	var isConfigValid = (config: SeatConfig)=>{
		return config && config.direction && config.initial_value && config.type && checkValidType(config.initial_value, config.type);
	}

	var getPreview = (coords: SelectionCoordinates[]): { row: string, number: string }[][] => {
		if(!coords || coords.length==0) return [];
		var bounds = coordsToBounds(coords);
		var preview_seats: { row: string, number: string }[][] = [];
		var empty_cols : number[] = [];
		for(var i=0; i<bounds.height; i++){
			var r = [];
			for(var j=0; j<bounds.width; j++){
				r.push(isSelected(bounds.start_x+j, bounds.start_y+i) ? { row: null, number: null } : null);
			}
			preview_seats.push(r);
		}

		for(var x=0; x<bounds.width; x++){
			var has_seat : boolean = false;
			for(var y=0; y<bounds.height; y++){
				if(!!preview_seats[y][x]){
					has_seat = true;
					break;
				}
			}
			if(!has_seat) empty_cols.push(x);
		}
		
		var getInitialVal = (type: SeatConfigType, val: string)=>type===SeatConfigType.LETTERS ? letterToNumber(val) : parseInt(val);

		if(isConfigValid(configRows)){
			var initval = getInitialVal(configRows.type, configRows.initial_value);
			var real_y = 0;
			var start_y = configRows.direction===SeatDirection.BOTTOM_TOP ? preview_seats.length-1 : 0;
			for(var y=start_y; configRows.direction===SeatDirection.BOTTOM_TOP ? y>=0 : y<preview_seats.length;){
				var real_seats = 0;
				for(var x=0; x<preview_seats[y].length; x++){
					if(preview_seats[y][x]){
						var v = (initval + real_y);
						preview_seats[y][x].row = configRows.type === SeatConfigType.NUMBERS ? v.toString() : numberToLetter(v);
						real_seats++;
					}
				}
				if(configRows.skip_empty) real_y++
				else if(real_seats>0) real_y++;

				if(configRows.direction===SeatDirection.BOTTOM_TOP) y--;
				else y++;
			}
		}

		if(isConfigValid(configCols)){
			var initval = getInitialVal(configCols.type, configCols.initial_value);

			for(var y=0; y<preview_seats.length; y++){
				var real_x = 0;
				var start_x = configCols.direction===SeatDirection.RIGHT_LEFT ? preview_seats[y].length-1 : 0;
				for(var x=start_x; configCols.direction===SeatDirection.RIGHT_LEFT ? x>=0 : x<preview_seats[y].length;){
					if(preview_seats[y][x]){
						var v = initval + real_x;
						preview_seats[y][x].number = configCols.type === SeatConfigType.NUMBERS ? v.toString() : numberToLetter(v);
					}

					if(configCols.skip_empty) real_x++;
					else if(empty_cols.indexOf(x)===-1) real_x++;

					if(configCols.direction===SeatDirection.RIGHT_LEFT) x--;
					else x++;
				}
			}
		}

		return preview_seats;
	}

	var showNumberModal = ()=>{
		if(!seatSelection || seatSelection.length==0 || sending) return;
		setContextMenu(null);
		if(isStageSelected()) return Toast.error('No se puede numerar el escenario.');
		
		setConfigRows(null);
		setConfigCols(null);
		var coords = getSelectionCoords();
		if(coords.length==1 && (coords[0].width*coords[0].height)==1){
			var seat = seats_selected && seats_selected.length>0 ? seats_selected[0] : null;
			setSingleConfig({
				row: seat?.seat_row || '',
				number: seat?.seat_number || '',
			});
		}
		setShownModal(ModalType.NUMBER);
	}

	var determineConfigType = (config: SeatConfig, setConfig: (c: SeatConfig)=>void)=>{
		return (val: string)=>{
			setConfig({
				...config,
				type: checkValidType(val, SeatConfigType.LETTERS) ? SeatConfigType.LETTERS : (checkValidType(val, SeatConfigType.NUMBERS) ? SeatConfigType.NUMBERS : null),
				initial_value: val
			});
		}
	}

	var numberSeats = ()=>{
		if(!seatSelection || seatSelection.length==0 || sending) return;
		if(!isConfigValid(configCols) && !isConfigValid(configRows)) return;

		
		// Check if stage overlaps
		if(isStageSelected()) return Toast.error('No se puede numerar el escenario.');

		var coords = getSelectionCoords();
		var bounds = coordsToBounds(coords);
		var numbered_seats = getPreview(coords);
		var new_seats = getSeatsClone();

		var y = bounds.start_y;
		for(var row of numbered_seats){
			var x = bounds.start_x;
			for(var s of row){
				if(s){
					var six = new_seats.findIndex(a=>a.seat_x==x && a.seat_y==y && !a.deleted);
					if(six===-1){
						new_seats.push({
							seat_id: null,
							section_id: section.section_id,
							seat_x: x,
							seat_y: y,
							seat_row: s.row,
							seat_number: s.number,
							seat_section: null,
							seat_status: SeatStatus.FREE,
							deleted: false,
						});
					}else{
						new_seats[six].seat_row = s.row;
						new_seats[six].seat_number = s.number;
						new_seats[six].dirty = true;
					}
				}
				x++;
			}
			y++;
		}
		setSeats(new_seats);
		setShownModal(null);
	}

	var numberSingleSeat = ()=>{
		if(!singleConfig || !(singleConfig.number.length>0 || singleConfig.row.length>0)){
			return Toast.error("La configuración de la numeración no es válida.");
		}
		var new_seats = getSeatsClone();
		var selection = getSelectionCoords();
		var edit_seats = getSelectedSeatsIndex();
		if(edit_seats.length==1){
			new_seats[edit_seats[0]].seat_row = singleConfig.row?.toUpperCase();
			new_seats[edit_seats[0]].seat_number = singleConfig.number?.toUpperCase();
			new_seats[edit_seats[0]].dirty = true;
		}else if(selection && selection.length==1 && (selection[0].width*selection[0].height)==1){
			new_seats.push({
				seat_id: null,
				section_id: section.section_id,
				seat_x: selection[0].start_x,
				seat_y: selection[0].start_y,
				seat_row: singleConfig.row.toUpperCase(),
				seat_number: singleConfig.number.toUpperCase(),
				seat_section: null,
				seat_status: SeatStatus.FREE,
				deleted: false,
			});
		}else{
			return Toast.error('Se esta usando la herramienta de edición simple en multiples butacas.');
		}
		
		setSeats(new_seats);
		setShownModal(null);
		Toast.success('Se ha numerado la butaca seleccionada.');
	}

	var setSeatStatus = (status_id: number = null)=>{
		if(sending) return false;
		setContextMenu(null);
		var selected = getSelectedSeatsIndex();
		if(selected.length==0) return;
		var new_seats = getSeatsClone();
		for(var i of selected){
			new_seats[i].seat_status = status_id ? status_id : seatStatus;
			new_seats[i].status_comment = seatComment;
			new_seats[i].dirty = true;
		}
		setSeatComment(null);
		setSeats(new_seats);
		setShownModal(null);
		Toast.success('Se ha asignado el estado de las butacas');
	}

	var setSeatSection = ()=>{
		if(sending) return false;
		var selected = getSelectedSeatsIndex();
		if(selected.length==0) return;
		var new_seats = getSeatsClone();
		for(var i of selected){
			new_seats[i].seat_section = sectionName.length==0 ? null : sectionName;
			new_seats[i].dirty = true;
		}
		setSeats(new_seats);
		setShownModal(null);
		Toast.success('Se ha asignado la sección de las butacas');
	}

	var moveSeats = (x: number, y: number)=>{
		return ()=>{
			if(sending) return false;
			var selected = getSelectedSeatsIndex();
			if(selected.length==0) return;
			var new_seats = getSeatsClone().filter((a, ix)=>selected.indexOf(ix)==-1);
			var moved_seats = getSeatsClone().filter((a, ix)=>selected.indexOf(ix)>-1);

			for(var i of moved_seats){
				i.seat_x += x;
				i.seat_y += y;
				i.dirty = true;
				var sx = new_seats.findIndex(a=>a.seat_x==i.seat_x && a.seat_y==i.seat_y && !a.deleted);

				// Check if seat pos is negative
				if(i.seat_x<0 || i.seat_y<0 || i.seat_y>=seatmap.seats_height || i.seat_x>=seatmap.seats_width){
					return Toast.error('Las butacas no se pueden salir del mapa de butacas.');
				}
				// Check if other overlaps other seats
				if(sx!=-1){
					return Toast.error('No se puede mover las butacas en esa dirección ya que ya existe una butaca en esa posición.');
				}
				// Check if touches stage
				if(isStage(i.seat_x, i.seat_y)){
					return Toast.error('Las butacas topan con el escenario.')
				}
			}

			if(seatSelection && seatSelection.length>0){
				var sel = [...seatSelection];
				for(var s of sel){
					s.start_x += x;
					s.start_y += y;
					s.end_x += x;
					s.end_y += y;
				}
				setSeatSelection(sel);
			}
			setSeats([
				...new_seats,
				...moved_seats,
			])
		}
	}

	var setStage = ()=>{
		if(sending) return false;
		setContextMenu(null);
		if(!seatSelection || seatSelection.length==0) return Toast.error('No se ha seleccionado nada en el mapa para el escenario');
		if(getSelectedSeats().length>0) return Toast.error('No se puede asignar escenario a butacas.');
		var bounds = getSelectionBounds();
		setSeatmap({
			...seatmap,
			dirty: true,
			stage_x: bounds.start_x,
			stage_y: bounds.start_y,
			stage_width: bounds.width,
			stage_height: bounds.height,
		});
	}

	var removeStage = ()=>{
		if(sending) return false;
		setContextMenu(null);
		setSeatmap({
			...seatmap,
			dirty: true,
			stage_x: null,
			stage_y: null,
			stage_width: null,
			stage_height: null
		});
	}

	var getDeleteConfirmation = ()=>{
		return getSelectedSeats().map(a=>formatSeatNumber(a.seat_row, a.seat_number)).slice(0, 5).join(', ');
	}

	var addRow = (row_y: number)=>{
		if(sending) return false;
		setContextMenu(null);
		if(row_y===null) return;
		var new_seats = getSeatsClone();
		for(var i of new_seats){
			if(i.seat_y>row_y && !i.deleted){
				i.seat_y += 1;
				i.dirty = true;
			}
		}
		setSeatmap({
			...seatmap,
			dirty: true,
			seats_height: seatmap.seats_height+1,
			stage_y: seatmap.stage_y+(seatmap.stage_y>row_y ? 1 : 0),
		});
		if(seatSelection && seatSelection.length>0){
			var sel = [...seatSelection];
			for(var s of sel){
				var { start: start_y, end: end_y } = fixStartEnd(s.start_y, s.end_y);
				if(start_y>=row_y) start_y += 1;
				if(end_y>= row_y) end_y += 1;
				s.start_y = start_y;
				s.end_y = end_y;
			}
		}
		setSeats(new_seats);
	}

	var addColumn = (col_x: number)=>{
		if(sending) return false;
		setContextMenu(null);
		if(col_x===null) return;
		var new_seats = getSeatsClone();
		for(var i of new_seats){
			if(i.seat_x>col_x && !i.deleted){
				i.seat_x += 1;
				i.dirty = true;
			}
		}
		setSeatmap({
			...seatmap,
			dirty: true,
			seats_width: seatmap.seats_width+1,
			stage_x: seatmap.stage_x+(seatmap.stage_x>col_x ? 1 : 0),
		});
		if(seatSelection && seatSelection.length>0){
			var sel = [...seatSelection];
			for(var s of sel){
				var { start: start_x, end: end_x } = fixStartEnd(s.start_x, s.end_x);
				if(start_x>=col_x) start_x += 1;
				if(end_x>= col_x) end_x += 1;
				s.start_x = start_x;
				s.end_x = end_x;
			}
		}
		setSeats(new_seats);
	}

	var removeRow = (start_y: number, end_y: number)=>{
		setContextMenu(null);
		if(start_y===null || end_y ===null) return;
		var new_seats = getSeatsClone();
		let length = 0;
		for(var i = start_y; i <=end_y; i++){
			length++
			let row_y = i;
			var row_seats = new_seats.filter(a=>a.seat_y==row_y && !a.deleted);
			if(row_seats.length>0){
				return Toast.error('No se puede eliminar la fila ya que hay butacas en esta fila.');
			}
			if(seatmap.stage_width && seatmap.stage_y<=row_y && (seatmap.stage_y+seatmap.stage_height-1)>=row_y){
				return Toast.error('No se puede eliminar la fila ya que el escenario se encuentra en esta fila.')
			}
		}
		
		for(var j of new_seats){
			if(j.seat_y>end_y && !j.deleted){
				j.seat_y = j.seat_y - length;
				j.dirty = true;
			}
		}
		setSeatmap({
			...seatmap,
			dirty: true,
			seats_height: seatmap.seats_height-length,
			stage_y: seatmap.stage_y-(seatmap.stage_y>start_y ? length : 0),
		});
		setSeats(new_seats);
	}

	var removeColumn = (col_x: number)=>{
		setContextMenu(null);
		if(col_x===null) return;
		var new_seats = getSeatsClone();
		
		var col_seats = new_seats.filter(a=>a.seat_x==col_x && !a.deleted);
		if(col_seats.length>0){
			return Toast.error('No se puede eliminar la columna ya que hay butacas en esta columna.');
		}

		if(seatmap.stage_width && seatmap.stage_x<=col_x && (seatmap.stage_x+seatmap.stage_width-1)>=col_x){
			return Toast.error('No se puede eliminar la columna ya que el escenario se encuentra en esta columna.')
		}

		for(var i of new_seats){
			if(i.seat_x>col_x && !i.deleted){
				i.seat_x -= 1;
				i.dirty = true;
			}
		}

		setSeatmap({
			...seatmap,
			dirty: true,
			seats_width: seatmap.seats_width-1,
			stage_x: seatmap.stage_x-(seatmap.stage_x>col_x ? 1 : 0),
		});
		// setSelection({
		// 	...selection,
		// 	start_x: selection.start_x-1,
		// 	end_x: selection.end_x-1,
		// })	
		setSeats(new_seats);
	}

	var showDeleteModal = async () => {
		var delete_seats = getSelectedSeats();
		const res = await API.isSeatDeletable(delete_seats.map(s => s.seat_id));
		if(res.error){
			return Toast.error(res.message);
		}
		if (res.data) {
			const notDeletable = res.data.filter(s => !s.deletable);
			if (notDeletable.length > 0) {
				var seatsid= notDeletable.map(s => s.seat_id);
				let butacasError = `Las siguientes butacas no se pueden eliminar: ${seatsid.join()}`;
				Toast.error(butacasError);
			} else {
				setContextMenu(null);
				setDeleteConfirm(DEBUG ? getDeleteConfirmation() : '');
				setShownModal(ModalType.DELETE_SEATS);
			}
		}
	}

	var deleteSeats = ()=>{
		var delete_seats = getSelectedSeatsIndex();
		if(delete_seats.length==0) return;
		var confirm_text = getDeleteConfirmation();
		if(deleteConfirm.trim()!==confirm_text) return Toast.error(`Favor de ingresar el texto de confirmación: "${confirm_text}"`);
		var new_seats = getSeatsClone();
		for(var i of delete_seats){
			new_seats[i].deleted = true;
			new_seats[i].dirty = true;
		}
		setSeats(new_seats);
		Toast.success('Se han eliminado las butacas, no se eliminarán por completo hasta guardar el mapa.');
		setShownModal(null);
	}

	var sendSave = ()=>{
		if(sending) return;
		var seats = getSeatsClone();
		// Solo las butacas que ya existian o que son nuevas y no estan eliminadas.
		var updated_seats = seats.filter(a=>a.seat_id || (!a.seat_id && !a.deleted)).map(a=>({
			seat_id: a.seat_id || null,
			seat_row: a.seat_row,
			seat_number: a.seat_number,
			seat_section: a.seat_section,
			seat_x: a.seat_x,
			seat_y: a.seat_y,
			seat_status: a.seat_status,
			status_comment: a.status_comment,
			deleted: a.deleted,
		}));
		if(seat_duplicates && seat_duplicates.length>0){
			return Toast.error(`Hay butacas con denominadores duplicados, favor de arreglarlos. (${seat_duplicates.join(', ')})`);
		}
		setSending(true);
		API.saveSeats(section.section_id, seatmap, updated_seats).then(res=>{
			if(res.error) return Toast.error(res.message);
			var seats = getSeatsClone();
			seats = seats.filter(a=>!a.deleted);
			for(var i of seats){
				i.dirty = false;
			}
			if(res.data && res.data.new_seats){
				for(var s of res.data.new_seats){
					var sx = seats.findIndex(a=>a.seat_x==s.seat_x && a.seat_y==s.seat_y && !a.deleted);
					if(sx==-1) continue;
					seats[sx].seat_id = s.seat_id;
				}
			}
			setSeats(seats);
			setSeatmap({
				...seatmap,
				dirty: false
			});
			Toast.success('Se ha guardado el mapa de butacas.');
		}).catch(err=>{
			Toast.error('Hubo un error inesperado guardando las butacas (LCL-1)');
		}).finally(()=>{
			setSending(false);
		})
	}

	var seat_denominators = [];
	var map : SeatmapSeat[][] = [];
	for(let y=0; y<seatmap.seats_height; y++){
		var row : SeatmapSeat[] = [];
		for(let x=0; x<seatmap.seats_width; x++){
			var seat = seats.find(a=>!a.deleted && a.seat_x===x && a.seat_y===y);
			if(seat){
				seat_denominators.push(formatSeatNumber(seat.seat_row, seat.seat_number));
			}
			row.push(seat || null);
		}
		map.push(row);
	}
	seat_denominators = seat_denominators.sort();

	var seat_duplicates : string[] = [];
	for(var sdi=0; sdi<seat_denominators.length-1; sdi++){
		if(seat_denominators[sdi+1]===seat_denominators[sdi]){
			seat_duplicates.push(seat_denominators[sdi]);
		}
	}

	const openCommentSeatModal = (status_id: number) => {
		setSeatStatusM(status_id)
		if (status_id !== SeatStatus.FREE) {
			setSeatComment('');
			setShownModal(ModalType.SEAT_COMMENT);
		} else {
			setSeatStatus(status_id);
		}
	}

	const setSeatZoom = (amt: number) => {
		return () => {
			setSeatSize(Math.max(Math.min(seatSize+amt, 1.5), 0.5));
		}
	}
	
	var onRowsConfigChange = bindFormChange(configRows, setConfigRows);
	var onColsConfigChange = bindFormChange(configCols, setConfigCols);
	var onSingleConfigChange = bindFormChange(singleConfig, setSingleConfig);

	return <div>
		<Groupper defaultStyle={false} fitted title={section?.section_name} className='map'>
			<div className="fr toolbar">
				<Popup
					openOnTriggerClick
					openOnTriggerMouseEnter={false}
					closeOnPortalMouseLeave={false}
					closeOnEscape={false}
					closeOnTriggerMouseLeave={false}
					position='bottom center'
					trigger={(
						<div className="item">
							<Icon name='crop' /> Mapa
						</div>
					)}
				>
					<Field label='Columnas' labelStyle={{ textAlign: 'center' }}>
						<div className="fr increment">
							<Button className='minus' color='black' iconName="minus" onClick={()=>updateMapColumns(-1)} />
							<div className="amount">{seatmap.seats_width}</div>
							<Button className='plus' color='black' iconName="plus" onClick={()=>updateMapColumns(1)} />
						</div>
					</Field>
					<Field label='Filas' labelStyle={{ textAlign: 'center' }}>
						<div className="fr increment">
							<Button className='minus' color='black' iconName="minus" onClick={()=>updateMapRows(-1)} />
							<div className="amount">{seatmap.seats_height}</div>
							<Button className='plus' color='black' iconName="plus" onClick={() => updateMapRows(1)} />
						</div>
					</Field>
				</Popup>
				<div className={classNames("item", { disabled: !has_selection })} onClick={showNumberModal}>
					<Icon name='hashtag' /> Numerar
				</div>
				<Dropdown disabled={!has_selection} icon={null} pointing={'top'} trigger={(
					<div className={`item ${!!has_selection ? '' : 'disabled'}`}>
						<Icon name='pencil' /> Editar
					</div>
				)}>
					<Dropdown.Menu style={{ width: '100%' }}>
						<Dropdown text='Estado' item style={{ padding: '0.78571429rem 1.14285714rem' }}>
							<Dropdown.Menu>
								{SEAT_STATUS_LOCALE.map(s=>(
									<Dropdown.Item onClick={()=> setSeatStatusM(s.id)} key={`SST-${s.id}`}>
										{s.name}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown.Item onClick={()=>setShownModal(ModalType.SECTION)}>Asignar Seccion</Dropdown.Item>
						<Dropdown.Item onClick={setStage}>Escenario</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<Popup
					openOnTriggerClick
					openOnTriggerMouseEnter={false}
					closeOnPortalMouseLeave={false}
					closeOnEscape={false}
					closeOnTriggerMouseLeave={false}
					position='bottom center'
					disabled={!has_selection}
					trigger={(
						<div className={classNames("item", {
							disabled: !has_selection
						})}>
							<Icon name='move' /> Mover
						</div>
					)}
				>
					<div className='fr centered'>
						<Button icon iconName='arrow-up' color='black' onClick={moveSeats(0, -1)} />
					</div>
					<div className='fr centered'>
						<Button icon iconName='arrow-left' color='black' onClick={moveSeats(-1, 0)} />
						<div className="fr blank"></div>
						<Button icon iconName='arrow-right' color='black' onClick={moveSeats(1, 0)} />
					</div>
					<div className='fr centered'>
						<Button icon iconName='arrow-down' color='black' onClick={moveSeats(0, 1)} />
					</div>
				</Popup>
				<div className={classNames("item")} onClick={() => {setShownModal(ModalType.SEAT_LABEL)}}>
					<Icon name='info' style={{ margin: 0 }} />
				</div>
				<div className={classNames('item', {
					black: stage_dirty,
					'fr loading': sending
				})} onClick={sendSave}>
					<Icon name="save" /> Guardar
				</div>
				<div className={classNames('item')} style={{ padding: 0, width: 90, borderRadius: 200 }} >
					<Button 
						icon 
						iconStyle={{ fontSize: 16 }} 
						onClick={setSeatZoom(-0.1)}
						iconName='magnifying-glass-minus'
						style={{ 
							width: '50%', 
							height: '100%', 
							borderTopRightRadius: 0, 
							borderBottomRightRadius: 0, 
							borderRight: '1px solid #DDD',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}} 
					/> 
					<Button 
						icon 
						iconStyle={{ fontSize: 16 }}  
						onClick={setSeatZoom(0.1)}
						iconName='magnifying-glass-plus'
						style={{ 
							width: '50%', 
							height: '100%', 
							borderTopLeftRadius: 0, 
							borderBottomLeftRadius: 0,
							display: 'flex',
							alignItems: 'center', 
							justifyContent: 'center'
						}} 
					/>
				</div>
			</div>
			<div className="fr tooled seatmap" onMouseDown={onSelectStart} onMouseMove={onSelectMove} onMouseUp={onSelectEnd} onContextMenu={onContextMenu}>
				<div className="seats">
					{map.map((row, y)=>(
						<div className="row" key={`smrw-${y}`}>
							{row.map((seat, x)=>{
								var seatRef = x==0 && y==0 ? firstSeatRef : null;
								var key = 'smpst-'+(seat?.seat_id || `(${x}, ${y})`)
								var is_stage = isStage(x, y);
								var selected = isSelected(x, y);

								if(is_stage){
									return <div style={effective_seat_size.seat_style} className={classNames("seat stage-normal", { selected })} key={key} ref={seatRef}>
										<div className="number">E</div>
									</div>
								}
								if(!seat) return <div style={effective_seat_size.seat_style} className={classNames('seat empty', { selected })} key={key} ref={seatRef} />
								var denominator = formatSeatNumber(seat.seat_row, seat.seat_number)
								
								return <div 
									className={classNames('seat', {
										error: seat_duplicates.indexOf(denominator)!=-1,
										selected,
										blocked: seat.seat_status===SeatStatus.BLOCKED,
										locked: seat.seat_status===SeatStatus.LOCKED,
										hidden: seat.seat_status===SeatStatus.HIDDEN,
										new: !seat.seat_id || seat.dirty
									})} 
									style={effective_seat_size.seat_style}
									ref={seatRef} 
									key={key}
								>
									<div className="number">
										{seat.seat_status!==SeatStatus.FREE ? (
											<i className={classNames('icon', {
												ban: seat.seat_status===SeatStatus.BLOCKED,
												lock: seat.seat_status===SeatStatus.LOCKED,
												'eye slash': seat.seat_status===SeatStatus.HIDDEN,
											})}></i>
										) : null}
										{denominator}
									</div>
									<div className="section">{seat.seat_section}</div>
								</div>
							})}
						</div>
					))}
				</div>
			</div>
		</Groupper>

		<Modal open={shownModal===ModalType.NUMBER} onClose={bindClose(setShownModal)} size={selection_bounds?.width>10 ? 'small' : 'tiny'}>
			<Modal.Header>Numeración</Modal.Header>
			<Modal.Content>
				{has_selection && (selection_bounds.width*selection_bounds.height)>1 && shownModal===ModalType.NUMBER ? <>
					<Header text='Preview' size='small' style={{ marginTop: -5 }} />
					<div className="fr small seatmap" style={{ height: 'auto' }}>
						<div className="seats">
							{getPreview(real_selection).map((row, ir) => (
								<div className="row" key={`PVW-r${ir}`}>
									{row.map((col, ic)=>(
										col ? (
											<Seatmap.Seat style={getSeatSize(0.6).seat_style} key={`PVW-r${ir}c${ic}`} text={formatSeatNumber(col.row, col.number)} />
										) : (
											<Seatmap.Seat style={getSeatSize(0.6).seat_style} empty key={`PVW-r${ir}c${ic}`} />
										)
									))}
								</div>
							))}
						</div>
					</div>
					<table className="fr fitted details divided table" style={{ marginTop: 15 }}>
						<thead>
							<tr>
								<th className='title' colSpan={2}>
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										Fila
										<Checkbox toggle checked={!!configRows} onChange={(e, d) => {
											setConfigRows(d.checked ? {
												direction: SeatDirection.TOP_BOTTOM,
												skip_empty: true,
											} : null);
										}} />
									</div>
								</th>
							</tr>
						</thead>
						{!!configRows ? (
							<tbody>
								<tr>
									<td>Valor inicial</td>
									<td>
										<Input 
											style={{ marginBottom: 0 }} 
											placeholder='Valor inicial' 
											onChange={determineConfigType(configRows, setConfigRows)} 
											error={configRows?.initial_value && configRows?.initial_value.length>0 && configRows?.type===null} 
										/>
									</td>
								</tr>
								<tr>
									<td>Dirección</td>
									<td>
										<Dropdown fluid selection
											value={configRows?.direction}
											onChange={onRowsConfigChange('direction', true)}
											placeholder={'Direccion'}
											options={[{
												text: 'Arriba abajo',
												value: SeatDirection.TOP_BOTTOM,
												icon: 'arrow down'
											}, {
												text: 'Abajo arriba',
												value: SeatDirection.BOTTOM_TOP,
												icon: 'arrow up'
											}]}
										/>
									</td>
								</tr>
								<tr>
									<td>Contar vacias</td>
									<td>
										<Checkbox label={'Numerar filas vacias'} style={{ margin: 0 }} checked={configRows?.skip_empty} onChange={onRowsConfigChange('skip_empty', true)} toggle />
									</td>
								</tr>
							</tbody>
						) : null}
					</table>
					<table className="fr fitted details divided table" style={{ marginTop: 0 }}>
						<thead>
							<tr>
								<th className='title' colSpan={2}>
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										Butacas
										<Checkbox toggle checked={!!configCols} onChange={(e, d) => {
											setConfigCols(d.checked ? {
												direction: SeatDirection.LEFT_RIGHT,
												skip_empty: true,
											} : null);
										}} />
									</div>
								</th>
							</tr>
						</thead>
						{!!configCols ? (
							<tbody>
								<tr>
									<td>Valor inicial</td>
									<td>
										<Input 
											style={{ marginBottom: 0 }} 
											placeholder='Valor inicial' 
											onChange={determineConfigType(configCols, setConfigCols)} 
											error={configCols?.initial_value && configCols?.initial_value.length>0 && configCols?.type===null} 
										/>
									</td>
								</tr>
								<tr>
									<td>Dirección</td>
									<td>
										<Dropdown fluid selection
											value={configCols?.direction}
											onChange={onColsConfigChange('direction', true)}
											placeholder={'Direccion'}
											options={[{
												icon: 'arrow right',
												text: 'Izquierda derecha',
												value: SeatDirection.LEFT_RIGHT,
											}, {
												icon: 'arrow left',
												text: 'Derecha izquierda',
												value: SeatDirection.RIGHT_LEFT,
											}]}
										/>
									</td>
								</tr>
								<tr>
									<td>Contar vacias</td>
									<td>
										<Checkbox label={'Numerar columnas vacias'} style={{ margin: 0 }} checked={configCols?.skip_empty} onChange={onColsConfigChange('skip_empty', true)} toggle />
									</td>
								</tr>
							</tbody>
						) : null}
					</table>
				</> : has_selection && (selection_bounds.width*selection_bounds.height)==1 ? <>
					<Input label='Fila' value={singleConfig?.row} onChange={onSingleConfigChange('row')} />
					<Input label='Butaca' value={singleConfig?.number} onChange={onSingleConfigChange('number')} />
				</> : null}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
				<Button text='Numerar' color='black' onClick={has_selection && (selection_bounds.width*selection_bounds.height)>1 ? numberSeats : numberSingleSeat} />
			</Modal.Actions>
		</Modal>
		{contextMenu ? (
			<Menu
				vertical
				className='context'
				style={{ 
					position: 'absolute', 
					zIndex: 100, 
					left: contextMenu?.x || -1000, 
					top: contextMenu?.y || -1000, 
					width: 200, 
					borderRadius: 12,
					display: contextMenu ? 'block' : 'none',
				}}
			>
				<Menu.Item name="Numerar" icon="hashtag" onClick={showNumberModal} />
				{!stage_selected ? (
					<Menu.Item name="Asignar escenario" icon="microphone" onClick={()=>setStage()} />
				) : (
					<Menu.Item name="Eliminar escenario" style={{ color: 'brown' }} icon="remove" onClick={removeStage} />
				)}
				<Dropdown item text='Estado' direction={!contextMenu?.left ? 'left': 'right'} pointing={!contextMenu?.left ? 'right' : 'left'}>
					<Dropdown.Menu>
						{SEAT_STATUS_LOCALE.map(s=>(
							<Dropdown.Item onClick={()=> openCommentSeatModal(s.id)} key={`SSTCX-${s.id}`}>
								{s.name}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
				<Dropdown item text="Agregar" direction={!contextMenu?.left ? 'left': 'right'} pointing={!contextMenu?.left ? 'right' : 'left'}>
					<Dropdown.Menu>
						<Dropdown.Item disabled={real_selection.length!=1 || seats_selected.length>0} text="Fila arriba" onClick={has_selection ? ()=>addRow(selection_bounds.start_y) : null} icon={(
							<Icon.Group>
								<Icon name='arrow up' />
								<Icon corner color='green' name='plus circle' style={{ marginRight: -4, marginBottom: -4, fontSize: 10 }} />
							</Icon.Group>
						)} />
						<Dropdown.Item disabled={real_selection.length!=1 || seats_selected.length>0} text="Columna izquierda" onClick={has_selection ? ()=>addColumn(selection_bounds.start_x) : null} icon={(
							<Icon.Group>
								<Icon name='arrow left' />
								<Icon corner color='green' name='plus circle' style={{ marginRight: -4, marginBottom: -4, fontSize: 10 }} />
							</Icon.Group>
						)} />
					</Dropdown.Menu>
				</Dropdown>
				<Dropdown item text="Eliminar" direction={!contextMenu?.left ? 'left': 'right'} pointing={!contextMenu?.left ? 'right' : 'left'}>
					<Dropdown.Menu>
						<Dropdown.Item disabled={real_selection.length!=1 || seats_selected.length>0} text="Fila" onClick={has_selection ? ()=>removeRow(selection_bounds.start_y, selection_bounds.end_y) : null} icon={(
							<Icon.Group>
								<i className="grip horizontal icon"></i>
								<Icon size='tiny' corner color='red' name='remove' />
							</Icon.Group>
						)} />
						<Dropdown.Item disabled={real_selection.length!=1 || seats_selected.length>0} text="Columna" onClick={has_selection ? ()=>removeColumn(selection_bounds.start_x) : null} icon={(
							<Icon.Group>
								<i className="grip vertical icon"></i>
								<Icon size='tiny' corner color='red' name='remove' />
							</Icon.Group>
						)} />
						<Dropdown.Item disabled={!seats_selected || seats_selected.length==0} text={`Eliminar BUTACA${seats_selected.length==1 ? '' : 'S'}`} onClick={showDeleteModal} style={{ color: 'brown' }} icon={(
							<Icon.Group >
								<i className="chair icon"></i>
								<Icon corner color='red' name='remove' style={{ marginRight: -5, marginBottom: -4, fontSize: 14 }} />
							</Icon.Group>
						)} />
					</Dropdown.Menu>
				</Dropdown>
			</Menu>
		) : null}
		<Modal open={shownModal===ModalType.SECTION} onClose={bindClose(setShownModal)} size='mini'>
			<Modal.Header>Asingar Seccion</Modal.Header>
			<Modal.Content>
				<Input
					label='Sección de las butacas'
					comment='La sección del grupo de butacas (Ej. Mesa 1, Palco 32)'
					value={sectionName}
					onChange={setSectionName}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
				<Button text='Guardar' color='black' onClick={setSeatSection} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===ModalType.DELETE_SEATS} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Eliminar Butacas</Modal.Header>
			<Modal.Content>
				<Header size='small' text='¿Eliminar las butacas seleccionadas?' />
				<ul style={{ paddingLeft: 15 }}>
					<li>Una vez eliminadas, despues de guardar el mapa de butacas no podrán ser recuperadas</li>
					<li>Si hay boletos vendidos con estas butacas en algún calendario <b>NO</b> serán cancelados</li>
					<li>Se podrán crear nuevas butacas en el mismo lugar pero <b>NO</b> serán las mismas butacas, ya que el ID de la butaca cambia.</li>
				</ul>
				{seats_selected.length>0 ? (
					<Field label='Confirmación' comment={`Ingresa el siguiente texto para eliminar las butacas:`}>
						<Input placeholder={getDeleteConfirmation()} comment={`"${getDeleteConfirmation()}"`} commentStyle={{ fontWeight: 'bold' }} value={deleteConfirm} onChange={setDeleteConfirm} />
					</Field>
				) : (
					<Header size='small' text='No hay butacas seleccionadas.' />
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
				{seats_selected.length>0 ? (
					<Button text='Eliminar' color={'red'} onClick={deleteSeats} />
				) : null}
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===ModalType.SEAT_COMMENT} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Cambio de Estatus</Modal.Header>
			<Modal.Content>
				<Header size='small' text='¿Porque estas cambiando de estado las butacas?' />
				<Input 
					value={seatComment} 
					onChange={setSeatComment} 
					label='Comentario' 
					comment={'Razón del cambio de estado de las butacas'}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
				<Button text='Guardar' color={'black'} onClick={() => { setSeatStatus() }} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal === ModalType.SEAT_LABEL} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Leyenda</Modal.Header>
			<Modal.Content>
				<Table fitted striped divided >
					{SEAT_STATUS_LOCALE.map(s=>(
						<Table.Row collapsingIndexes={[0]} key={s.id}
							data={[
								<Seatmap.Seat status={s.id} iconName={classNames({
									ban: s.id === SeatStatus.BLOCKED,
									lock: s.id === SeatStatus.LOCKED,
									'eye-slash': s.id === SeatStatus.HIDDEN,
								}) as any} />,
								s.name,
							]}
						/>
					))}
					<Table.Row
						data={[
							<Seatmap.Seat stage={[1,1,1,1]} text='E' />,
							'Escenario'
						]}
					/>
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClose(setShownModal)} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default SeatmapEdit;