import React, { useState, useEffect } from "react";
import { Button, Header, Table, Icon, Pagination } from 'react-frontier';
import API from "../../API";
import { CatalogEvent, PDV, PDVZone, SetLoading, Zone } from "@arema/components/Classes";
import { SearchEventModal, SearchZoneModal } from "../../components";
import { bindClose } from "@arema/components/Util";
import moment from "moment";
import { useUser } from "../../AdminHooks";
import { Dropdown, Modal } from "semantic-ui-react";
import Toast, { toast } from 'react-hot-toast';

enum Modals {
	'SEARCH_ZONE' = 1,
	'CONFIRM_ZONE_DELETE' = 3,

	'SEARCH_EVENT' = 20,
	'CONFIRM_EVENT_DELETE' = 21,
}

interface EventPdv {
	event_id: number,
	event_name: string,
	promoter_name: string,
	venue_name: string,
	date: number,
}

var PdvZones = (props: { pdv_id: number, pdv: PDV }) => {
	var [zones, setZones] = useState<PDVZone[]>(null);
	var [events, setEvents] = useState<EventPdv[]>(null);
	var [selectDeleteZone, setDeleteSelectZone] = useState<PDVZone>(null);
	var [selectDeleteEvent, setDeleteSelectEvent] = useState<EventPdv>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [modal, setModal] = useState<Modals>(null);
	var { user } = useUser()

	var PAGE_SIZE = 15;
	var [pageEvent, setPageEvent] = useState<number>(0);
	var [pageZone, setPageZone] = useState<number>(0);

	useEffect(() => {
		if (!zones && !props.pdv.all_events) {
			loadPDVZones(0);
			loadPDVEvents(0);
		} else if (props.pdv.all_events) {
			setZones([]);
			setEvents([]);
		}
	}, []);

	var loadPDVZones = (page: number) => {
		API.getPDVZones(props.pdv_id, page * PAGE_SIZE, PAGE_SIZE).then(res => {
			if (res.error) return setLoadError(res.message);
			setZones(res.data);
		}).catch(err => {
			setLoadError('Hubo un error cargando las zonas. (LCL-1)');
		});
	}

	var onSelectZone = (zone: Zone, setLoading: SetLoading) => {
		if (zones.some(z => z.zone_id === zone.zone_id)) {
			return Toast.error('Esta zona ya esta en el pdv');
		}
		setLoading(true);
		API.savePDVZone(props.pdv_id, zone.zone_id).then(z => {
			if (z.error) return Toast.error(z.message);
			setZones(zones => {
				let new_zones = [...zones];
				new_zones.push({
					zone_id: zone.zone_id,
					description: zone.description,
					zone_name: zone.zone_name,
					date_added: moment().unix(),
					admin_id: user.admin_id,
					admin_added: user.username,
				})
				return new_zones;
			});
			setModal(null);
			return Toast.success('Se guardo la zona correctamente');
		}).catch(() => {
			return Toast.error('Hubo un error al guardar la zona. (LCL-2)');
		}).finally(() => {
			setLoading(false);
		})
	}

	var onSelectDeleteZone = (zone: PDVZone) => {
		return () => {
			setDeleteSelectZone(zone);
			setModal(Modals.CONFIRM_ZONE_DELETE);
		}
	}

	var deleteZone = (setLoading: SetLoading) => {
		setLoading(true);
		var zone_id = selectDeleteZone.zone_id;
		API.deletePDVZone(props.pdv_id, zone_id).then((res) => {
			if (res.error) return Toast.error(res.message);
			var newZones = [...zones].filter(z => z.zone_id !== selectDeleteZone.zone_id);
			setZones(newZones);
			setModal(null);
			return Toast.success('Se eliminó la zona correctamente.');
		}).catch(() => {
			return Toast.error('Error inesperado (LCL-4)');
		}).finally(() => {
			setLoading(false);
		});
	}

	var onPageZoneChange = (page: number) => {
		setPageZone(page);
		loadPDVZones(page);
	}

	var loadPDVEvents = (page: number) => {
		setPageEvent(page);
		API.getPDVEvents(props.pdv_id, page * PAGE_SIZE, PAGE_SIZE).then(res => {
			if (res.error) return setLoadError(res.message);
			setEvents(res.data);
		}).catch(err => {
			setLoadError('Hubo un error cargando los eventos. (LCL-7)');
		});
	}

	var onSelectDeleteEvent = (event: EventPdv) => {
		return () => {
			setDeleteSelectEvent(event);
			setModal(Modals.CONFIRM_EVENT_DELETE);
		}
	}

	var deleteEvent = (setLoading: SetLoading) => {
		setLoading(true);
		var event_id = selectDeleteEvent.event_id
		API.deletePDVEvent(props.pdv_id, event_id).then((res) => {
			if (res.error) return Toast.error(res.message);
			var newEvents = [...events].filter(ev => ev.event_id !== selectDeleteEvent.event_id);
			setEvents(newEvents);
			setModal(null);
			return Toast.success('Se eliminó el evento correctamente.');
		}).catch(() => {
			return Toast.error('Error inesperado (LCL-4)');
		}).finally(() => {
			setLoading(false);
		});
	}

	var onEventSelected = (event: CatalogEvent) => {
		if (!event) return false;
		if (events.some(e => e.event_id === event.event_id)) {
			Toast.error('Este evento ya esta en este PDV');
			return false;
		}
		API.savePDVEvent(props.pdv_id, event.event_id).then((res) => {
			if (res.error) {
				Toast.error(res.message);
				return false;
			} else {
				setEvents(evs => {
					let new_events = [...evs];
					new_events.push({
						event_id: event.event_id,
						event_name: event.event_name,
						date: event.date,
						promoter_name: '',
						venue_name: event.venue_name
					})
					return new_events;
				});
				Toast.success('Se agrego correctamente el evento')
				return true;
			}
		}).catch(() => {
			Toast.error('Error inesperado agregand el evento (LCL-5)')
		});
	}

	var onPageEventChange = (page: number) => {
		setPageEvent(page);
		loadPDVEvents(page);
	}

	if (loadError) return <Header text="Error" subtext={loadError} />
	if (!zones || !events) return <Header loading text="Cargando eventos y zonas" />

	var hasNextEv = events?.length >= PAGE_SIZE;
	var hasNextZne = zones?.length >= PAGE_SIZE;
	return <div>

		<Table
			striped
			title="Zonas"
			headers={['ID', 'Zona', 'Usuario', <Icon name='pen' />]}
			style={{ maxWidth: 600, margin: 'auto', marginBottom: 10 }}
			empty={zones.length === 0}
			emptyText={props.pdv.all_events ? "El PDV tiene acceso a todos los eventos" : "No hay zonas en este PDV"}
			collapsingIndexes={[0, 2, 3]}
			centeredIndexes={[3]}
			button={!props.pdv.all_events && (
				<Button text="Agregar" size="tiny" color="black" iconName="plus-circle" onClick={bindClose(setModal, Modals.SEARCH_ZONE)} />
			)}
			footer={(<Table.Row>
				<Table.Cell colSpan={4} >
					<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} >
						<Pagination page={pageZone} onPageChange={onPageZoneChange} hasNext={hasNextZne} />
					</div>
				</Table.Cell>
			</Table.Row>)}
		>
			{zones.map(a => (
				<Table.Row
					key={a.zone_id}
					collapsingIndexes={[0, 2, 3]}
					centeredIndexes={[3]}
					data={[
						a.zone_id,
						a.zone_name,
						a.admin_added,
						<Dropdown icon={null} trigger={(
							<Button icon color='black' iconName="pen" size="tiny" />
						)}>
							<Dropdown.Menu>
								<Dropdown.Item icon='remove' text='Eliminar' onClick={onSelectDeleteZone(a)} />
							</Dropdown.Menu>
						</Dropdown>
					]}
				/>
			))}
		</Table>

		<Table
			striped
			title="Eventos"
			headers={['ID', 'Evento', 'Promotor', 'Recinto', 'Siguiente Fecha', <Icon name='pen' />]}
			style={{ maxWidth: 800, margin: 'auto' }}
			empty={events?.length === 0}
			emptyText={props.pdv.all_events ? "El PDV tiene acceso a todos los eventos" : "No hay eventos en este PDV"}
			collapsingIndexes={[0, 4, 5]}
			button={!props.pdv.all_events && (
				<Button text="Agregar Evento" size="tiny" color="black" iconName="plus-circle" onClick={bindClose(setModal, Modals.SEARCH_EVENT)} />
			)}
			footer={(<Table.Row>
				<Table.Cell colSpan={6} >
					<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} >
						<Pagination page={pageEvent} onPageChange={onPageEventChange} hasNext={hasNextEv} />
					</div>
				</Table.Cell>
			</Table.Row>)}
		>
			{events?.map(a => (
				<Table.Row
					key={a.event_id}
					collapsingIndexes={[0, 4, 5]}
					data={[
						a.event_id,
						a.event_name,
						a.promoter_name,
						a.venue_name,
						moment.unix(a.date).format('DD/MM/YY HH:mm:ss'),
						<Dropdown icon={null} trigger={(
							<Button icon color='black' iconName="pen" size="tiny" />
						)}>
							<Dropdown.Menu>
								<Dropdown.Item
									icon='remove'
									text='Eliminar'
									onClick={onSelectDeleteEvent(a)}
								/>
							</Dropdown.Menu>
						</Dropdown>
					]}
				/>
			))}
		</Table>

		<Modal open={modal === Modals.CONFIRM_ZONE_DELETE} onClose={bindClose(setModal)} size="tiny">
			<Modal.Header>¿Eliminar zona del pdv?</Modal.Header>
			<Modal.Content>
				<Header text={`¿Eliminar la zona "${selectDeleteZone?.zone_name}" de este PDV?`} />
				<Table title="Detalles" striped>
					<Table.Row data={['ID', selectDeleteZone?.zone_id]} details />
					<Table.Row data={['Nombre', selectDeleteZone?.zone_name]} details />
					<Table.Row data={['Descripción', selectDeleteZone?.description]} details />
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={bindClose(setModal)} text="Cancelar" basic />
				<Button text="Eliminar" color="black" onClick={deleteZone} />
			</Modal.Actions>
		</Modal>

		<Modal open={modal === Modals.CONFIRM_EVENT_DELETE} onClose={bindClose(setModal)} size="tiny">
			<Modal.Header>¿Eliminar evento del pdv?</Modal.Header>
			<Modal.Content>
				<Header text={`¿Eliminar el evento "${selectDeleteEvent?.event_name}" de este PDV?`} />
				<Table title="Detalles" striped>
					<Table.Row data={['ID', selectDeleteEvent?.event_id]} details />
					<Table.Row data={['Nombre', selectDeleteEvent?.event_name]} details />
					<Table.Row data={['Promotor', selectDeleteEvent?.promoter_name]} details />
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={bindClose(setModal)} text="Cancelar" basic />
				<Button text="Eliminar" color="black" onClick={deleteEvent} />
			</Modal.Actions>
		</Modal>

		<SearchZoneModal
			onClose={bindClose(setModal)}
			open={modal === Modals.SEARCH_ZONE}
			onSelected={onSelectZone}
		/>
		<SearchEventModal
			open={Modals.SEARCH_EVENT === modal}
			onClose={bindClose(setModal)}
			onEventSelected={onEventSelected}
		/>
	</div>
}

export default PdvZones;