import React, { useEffect, useState } from "react";
import { Button, Field, Groupper, Input, Message, Header, Table, Toolbar } from 'react-frontier';
import { UserAdmin as UserAdminClass, SetLoading } from '@arema/components/Classes';
import { bindClose, bindFormChange } from "@arema/components/Util";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, Modal } from "semantic-ui-react";
import { NotFound } from "../../components";
import { useUser } from "../../AdminHooks";
import API from "../../API";
import Validator from "@arema/components/Validator";
import Toast from 'react-hot-toast';
import UserAccess from "../../UserAccess";
import moment from "moment";

interface AccessCategory{
	category_id: number,
	category_name: string,
	access: {
		access_id: number,
		access_name: string,
		restricted: boolean
	}[]
}

enum PASSWORD_MODE{
	AUTOGENERATED = 0,
	PROVIDED = 1,
}

enum Modals{
	CHANGE_PASSWORD = 1,
	CREATION_TABLE = 2,
	CLONE_USER = 3,
	REFRESH_TOKEN = 4,
}

interface UserClone {
	username: string,
	email: string,
	first_name: string,
	last_name: string,
}

interface UserDetailsProps{
	onUserData: (v: UserAdminClass)=>void
}

var UserDetails = (props: UserDetailsProps)=>{
	var params = useParams<{ id: string }>();
	var { user: loggedUser, hasAccess } = useUser();
	var [user, setUser] = useState<UserAdminClass>(null);
	var [access, setAccess] = useState<number[]>(null);
	var [allAccess, setAllAccess] = useState<AccessCategory[]>(null);
	var [formPrompts, setFormPrompts] = useState<string[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [shownModal, setShownModal] = useState<Modals>(null);
	var [savedActive, setSavedActive] = useState<boolean>(false);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [userCreator, setUserCreator] = useState<UserAdminClass>(null);
	var [passwordForm, setPasswordForm] = useState<{ mode: PASSWORD_MODE, password: string }>(null);
	var [userClonForm, setUserClonForm] =	useState<UserClone>(null);
	var [modalConfirm, setModalConfirm] = useState<boolean>(false);
	var navigate = useNavigate();

	useEffect(()=>{
		if(!user && params && params.id && !Number.isNaN(parseInt(params.id))){
			API.getUser(parseInt(params.id), true, true).then(res=>{
				if(res.error) return setLoadError(res.message);
				setUser(res.data.user);
				setSavedActive(res.data.user.active);
				setAccess(res.data.access);
				setAllAccess(res.data.all_access);
				if(props.onUserData) props.onUserData(res.data.user);
			}).catch(err=>{
				return setLoadError('Hubo un error ineseprado cargando el usuario.');
			});
		}
	}, [params.id]);

	if(!params || !params.id || Number.isNaN(parseInt(params.id))){
		return <NotFound />
	}

	if(!user || loadError) return (
		<Header 
			loading={!loadError} 
			text={loadError ? 'Error' :  "Cargando usuario"} 
			subtext={loadError} 
			iconName={loadError ? "face-frown-open" : null} 
		/>
	)

	var submit = (setLoading: SetLoading)=>{
		if(!canEdit) return;
		var { valid, prompts } = Validator(user, {
			username: [{
				rule: /^[0-9a-zA-Z]{4,32}$/, prompt: 'El nombre de usuario no es válido',
			}],
			email: [{
				rule: 'email', prompt: 'El correo electrónico no es válido'
			}],
			first_name: [{
				rule: 'length', params: [3, 64], label: 'Nombre'
			}],
			last_name: [{
				rule: 'length', params: [3, 64], label: 'Apellido'
			}],
		});
		setFormPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editUser(parseInt(params.id), {
			username: user.username,
			email: user.email,
			first_name: user.first_name,
			last_name: user.last_name,
			admin_access: user.admin_access,
			active: user.active,
		}).then(res=>{
			if(res.error) return setFormPrompts([res.message]);
			setSavedActive(user.active);
			Toast.success('Se ha editado el usuario.');
		}).catch(err=>{
			return setFormPrompts(['Hubo un error inesperado editando el usuario (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var saveAccess = (setLoading: SetLoading)=>{
		if(!hasAccess(UserAccess.USERS.EDIT_ACCESS)) return;
		setLoading(true);
		API.saveAccess(user.admin_id, access).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Se han editado los permisos del usuario.');
		}).catch(err=>{
			Toast.error('Hubo un error inesperado editando los permisos del usuario (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var changePassword = ()=>{
		if(!canChangePassword) return;
		setPasswordForm({
			mode: PASSWORD_MODE.AUTOGENERATED,
			password: ''
		});
		setShownModal(Modals.CHANGE_PASSWORD);
	}

	function arrangeAccess<T>(access: T[]){
		var arrange : T[][] = [];
		for(var i=0; i<access.length; i+=2){
			var r = [];
			r.push(access[i]);
			if((i+1)<access.length) r.push(access[i+1]);
			arrange.push(r);
		}
		return arrange;
	}

	var onAccessChange = (access_id: number)=>{
		return (_: any, data: { checked?: boolean })=>{
			var acc = [...access];
			acc = acc.filter(a=>a!=access_id);
			if(data.checked){
				acc.push(access_id);
			}
			setAccess(acc);
		}
	}

	var setCategoryAccess = (category_id: number)=>{
		return (_: any, data: { checked?: boolean })=>{
			var cat = allAccess.find(a=>a.category_id==category_id);
			if(!cat) return false;
			var acc = [...access];
			var cat_acc = cat.access.map(a=>a.access_id);
			acc = acc.filter(a=>cat_acc.indexOf(a)==-1);
			if(data.checked){
				acc.push(...cat.access.filter(a=>!a.restricted).map(a=>a.access_id));
			}

			setAccess(acc);
		}
	}

	var isCategoryComplete = (category_id: number)=>{
		var cat = allAccess.find(a=>a.category_id==category_id);
		if(!cat) return false;
		var cat_acc = cat.access.filter(a=>!a.restricted).map(a=>a.access_id);
		for(var i of cat_acc){
			if(access.indexOf(i)==-1) return false;
		}
		return true;
	}

	var sendPasswordChange = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(passwordForm, {
			mode: ['number'],
			password: passwordForm.mode===PASSWORD_MODE.AUTOGENERATED ? [] : [
				{ rule: 'password' }
			]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.changeUserPassword(user.admin_id, passwordForm.mode===PASSWORD_MODE.AUTOGENERATED, passwordForm.password).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha cambiado la contraseña del usuario y se le ha enviado por correo.');
			setPasswordForm(null);
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado cambiando la contraseña (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var showCreationModal = ()=>{
		setShownModal(Modals.CREATION_TABLE);
		setModalPrompts([]);
		if(!userCreator){
			API.getUsers([user.user_creator]).then(res=>{
				if(res.error) return;
				setUserCreator(res.data[0]);
			}).catch(err=>{});
		}
	}

	var showClonModal = ()=>{
		setShownModal(Modals.CLONE_USER);
		setModalPrompts([]);
		setUserClonForm({
			username: '',
			email: '',
			first_name: '',
			last_name: '',
		})
	}

	var showRefreshModal = ()=>{
		setShownModal(Modals.REFRESH_TOKEN);
		setModalConfirm(false);
		setModalPrompts([]);
	}
	
	var saveClone = (setLoading: SetLoading ) => {
		var { valid, prompts } = Validator(userClonForm, {
			username: [{
				rule: /^[0-9a-zA-Z]{4,32}$/, prompt: 'El nombre de usuario no es válido',
			}],
			email: [{
				rule: 'email', prompt: 'El correo electrónico no es válido'
			}],
			first_name: [{
				rule: 'length', params: [3, 64], label: 'Nombre'
			}],
			last_name: [{
				rule: 'length', params: [3, 64], label: 'Apellido'
			}],
		});
		if(!valid) return Toast.error(prompts.join());
		setLoading(true);
		API.cloneUser({
			username: userClonForm.username,
			email: userClonForm.email,
			first_name: userClonForm.first_name,
			last_name: userClonForm.last_name,
			id_original_user: user.admin_id
		}).then(res=>{
			if(res.error) return Toast.error(res.message);
			setShownModal(null);
			setUser(null);
			Toast.success('Se ha guardo el usuario.');
			navigate(`/admins/${res.data.admin_id}`);
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado al clonar el usuario (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		});
	}

	var sendReinitialize = (setLoading: SetLoading)=>{
		setLoading(true);
		API.refreshAdminSecret(user.admin_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha reiniciado.');
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado reinicializando el PDV. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var onUserChange = bindFormChange(user, setUser);
	var onPassChange = bindFormChange(passwordForm, setPasswordForm);
	var onUserCloneChange = bindFormChange(userClonForm, setUserClonForm);
	var is_dev = hasAccess(UserAccess.ADMIN.DEVELOPER);
	var canEdit = hasAccess(UserAccess.USERS.EDIT_USERS);
	var canChangePassword = hasAccess(UserAccess.USERS.CHANGE_PASSWORD) && user && (!user.superadmin || loggedUser.superadmin);
	var canAdminAccess = hasAccess(UserAccess.USERS.GIVE_ADMIN_ACCESS);
	var canActivate = hasAccess(UserAccess.USERS.ACTIVATE_USER);
	var canRefresh = hasAccess(UserAccess.USERS.REFRESH_USER)
	var canCreate = hasAccess(UserAccess.USERS.CREATE_USERS)

	return <div>
		{!savedActive && (
			<Message 
				centered
				type="error"
				header="Usuario desactivado"
				text="Usuario desactivado por administrador"
				style={{ maxWidth: 600, margin: 'auto', marginBottom: 15 }}
			/>
		)}
		<Groupper width={600} title="Usuario Administrador" titleRight={`ID: ${user.admin_id}`}>
			<Toolbar fitted>
				<Toolbar.Item text="Ver creación"  onClick={showCreationModal} />
				{canCreate && <Toolbar.Item text="Clonar usuario" icon="copy" onClick={showClonModal} />}
				{canRefresh && hasAccess(UserAccess.USERS.REFRESH_USER)}
				<Toolbar.Item text="Reiniciar Sesion" icon="refresh" onClick={showRefreshModal} />
			</Toolbar>
			<Input label="Usuario" placeholder="Nombre de usuario" value={user.username} readonly={!canEdit} onChange={onUserChange('username')} />
			<Input label="Correo electrónico" value={user.email} readonly={!canEdit} onChange={onUserChange('email')} />
			<Field amount={2}>
				<Input label="Nombre" readonly={!canEdit} value={user.first_name} onChange={onUserChange('first_name')} />
				<Input label="Apellido" readonly={!canEdit} value={user.last_name} onChange={onUserChange('last_name')} />
			</Field>
			{canEdit && canActivate && (
				<Field label="Activo" comment="Activar usuario">
					<Checkbox toggle label={'Usuario activado'} checked={!!user.active} onChange={onUserChange('active', true)} />
				</Field>
			)}
			{canEdit && canAdminAccess && (
				<Field label="Administrador" comment="Activar para darle acceso a el administrador web a el usuario">
					<Checkbox toggle label={'Acceso a el administrador'} checked={!!user.admin_access} onChange={onUserChange('admin_access', true)} />
				</Field>
			)}
			<Message type="error" list={formPrompts} />
			{canEdit || canChangePassword ? (
				<div className="actions">
					{canChangePassword && <Button text="Cambiar contraseña" basic onClick={changePassword} />}
					{canEdit && <Button text="Guardar" color="black" onClick={submit} />}
				</div>
			) : null}
		</Groupper>
		{hasAccess(UserAccess.USERS.EDIT_ACCESS) && !user.superadmin && (
			<Groupper width={600} style={{ marginTop: 15 }} title="Permisos" actions={(
				<Button text="Guardar" color="black" onClick={saveAccess} />
			)}>
				{allAccess.map(a=>(
					<div key={`acc-${a.category_id}`}>
						<Header size="small" style={{ textAlign: 'left' }}>
							<Checkbox 
								className="big" 
								disabled={!a.access.find(a=>!a.restricted)}
								onChange={setCategoryAccess(a.category_id)} 
								checked={isCategoryComplete(a.category_id)} 
								style={{ marginRight: 20, marginLeft: -2 }} 
							/>
							{a.category_name}
						</Header>
						{arrangeAccess(a.access).map((b, i)=>(
							<Field amount={2} style={{ marginBottom: 0 }} key={`acc-fld-${a.category_id}-${i}`}>
								<Field style={{ marginBottom: 5 }}>
									<Checkbox checked={access.indexOf(b[0].access_id)!=-1} onChange={onAccessChange(b[0].access_id)} label={<label>
										<span style={{ color: 'gray', fontSize: 12 }}>{(is_dev ? `[${b[0].access_id}] ` : '')}</span>
										<span style={b[0].restricted ? { color: 'brown' } : {}}>{(b[0].access_name)}</span>
									</label>} />
								</Field>
								{b.length>1 ? (
									<Checkbox checked={access.indexOf(b[1].access_id)!=-1} onChange={onAccessChange(b[1].access_id)} label={<label>
										<span style={{ color: 'gray', fontSize: 12 }}>{(is_dev ? `[${b[1].access_id}] ` : '')}</span>
										<span style={b[1].restricted ? { color: 'brown' } : {}}>{(b[1].access_name)}</span>
									</label>} />
								) : null}
							</Field>
						))}
						<div style={{ marginBottom: 25 }} />
					</div>
				))}
				<Message type="info" text={`El usuario tiene ${access ? access.length : 0} permiso${access && access.length==1 ? '' : 's'}.`} />
			</Groupper>
		)}
		{hasAccess(UserAccess.USERS.EDIT_ACCESS) && !!user.superadmin && (
			<Groupper title="Permisos" width={600} style={{ marginTop: 15 }}>
				<Header text="Usuario superadmin" subtext="Este usuario es un superadmin, ya puede hacer todo en el sistema." />
			</Groupper>
		)}
		{!!canChangePassword && (
			<Modal open={shownModal===Modals.CHANGE_PASSWORD} onClose={bindClose(setShownModal)} size="tiny">
				<Modal.Header>Cambiar contraseña</Modal.Header>
				<Modal.Content>
					<Header text="Cambiar contraseña de usuario" subtext="Se cambiará la contraseña del usuario y se le mandará un correo con la contraseña." />
					<Checkbox label="Autogenerar contraseña" style={{ marginTop: 10 }} checked={passwordForm && passwordForm.mode==PASSWORD_MODE.AUTOGENERATED} onChange={(e, v)=>setPasswordForm({ mode: v.checked ? PASSWORD_MODE.AUTOGENERATED : PASSWORD_MODE.PROVIDED, password: passwordForm.password })} />
					{passwordForm && passwordForm.mode==PASSWORD_MODE.PROVIDED ? (
						<Input label="Contraseña" style={{ marginTop: 10 }} value={passwordForm.password} onChange={onPassChange('password')}/>
					) : null}
					<Message text="El usuario deberá de ingresar a el administrador web para realizar un cambio final de contraseña." centered type="info" style={{ marginTop: 10 }} />
					<Message type="error" list={modalPrompts} style={{ marginTop: 10 }} />
				</Modal.Content>
				<Modal.Actions>
					<Button text="Cancelar" basic onClick={bindClose(setShownModal)} />
					<Button text="Guardar" color="black" onClick={sendPasswordChange} />
				</Modal.Actions>
			</Modal>
		)}
		<Modal open={shownModal===Modals.CREATION_TABLE} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Tabla de creación</Modal.Header>
			<Modal.Content>
				<Table
					fitted
					striped
					details
					className="last"
					data={[
						['Fecha creación', moment.unix(user.date_created).format('DD/MMM/YY HH:mm')],
						['Usuario creador', userCreator ? (
							`[${userCreator.admin_id}] ${userCreator.first_name} ${userCreator.last_name}`
						) : <>
							[{user.user_creator}]
							<div className="fr inline loading small" style={{ display: 'inline', marginLeft: 20 }}></div>
						</>],
					]}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" onClick={bindClose(setShownModal)} />
			</Modal.Actions>
		</Modal>
		{canCreate && 
			<Modal open={shownModal===Modals.CLONE_USER} onClose={bindClose(setShownModal)} size="tiny">
				<Modal.Header>Clonar Usuario</Modal.Header>
				<Modal.Content>
					{ userClonForm ? <div>
						<Input label={'Usuario'} value={userClonForm.username} onChange={onUserCloneChange('username')} />
						<Input label={'Correo electrónico'} value={userClonForm.email} onChange={onUserCloneChange('email')} />
						<Input label={'Nombre'} value={userClonForm.first_name} onChange={onUserCloneChange('first_name')} />
						<Input label={'Apellido'} value={userClonForm.last_name} onChange={onUserCloneChange('last_name')} />
						<Message text={'La contraseña del usuario se le enviará a el correo electrónico que se ingresó.'} type='info' style={{ marginTop: 15, marginBottom: 15 }} />
						<Message list={modalPrompts} type='error' />
					</div> : null }
				</Modal.Content>
				<Modal.Actions>
					<Button text="Cerrar" onClick={bindClose(setShownModal)} />
					<Button text='Guardar' color='black' onClick={saveClone}  />
				</Modal.Actions>
			</Modal>
		}
		
		{canRefresh && (
			<Modal open={shownModal===Modals.REFRESH_TOKEN} size='tiny' onClose={bindClose(setShownModal)}>
				<Modal.Header>Reiniciar Sesion</Modal.Header>
				<Modal.Content>
					<Header text='¿Reiniciar sesion del usuario?' style={{ marginBottom: 20 }} />
					<Message type='info' centered>
						Esto cerrará sesión en todos los dispositivos donde se inició sesión con este usuario. Se tendrá que acceder de nuevo con usuario y contraseña.
					</Message>
					<Checkbox checked={!!modalConfirm} onChange={bindClose(setModalConfirm, true)} label={'Deseo reiniciar la sesión'} style={{ marginTop: 20 }} />
					<Message list={modalPrompts} type='error' />
				</Modal.Content>
				<Modal.Actions>
					<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
					<Button text='Continuar' color='black' disabled={!modalConfirm} onClick={sendReinitialize} />
				</Modal.Actions>
			</Modal>
		)}
	</div>
}

export default UserDetails;