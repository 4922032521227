import classNames from 'classnames';
import React from 'react';

interface SegmentControlProps{
	values: any[],
	selected: boolean[],
	onSelected: (selected: boolean[])=>void,
	style?: React.CSSProperties,
	className?: string,
}

var SegmentControl = (props: SegmentControlProps)=>{
	var onSelected = (ix: number)=>{
		return ()=>{
			var val = [...props.selected];
			val[ix] = !val[ix];
			return props.onSelected(val);
		}
	}

	return <div className={classNames('fr segments', props.className)}>
		{props.values.map((a, i)=>(
			<div className={classNames("value", {
				selected: !!props.selected[i]
			})} onClick={onSelected(i)}>{a}</div>
		))}
	</div>
}

export default SegmentControl;