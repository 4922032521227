import React, { useState, useEffect } from "react";
import { Header } from 'react-frontier';
import { useParams } from "react-router-dom";
import { PDV } from "@arema/components/Classes";
import { NotFound, SidebarContainer } from "../../components";
import { useUser } from "../../AdminHooks";
import { PDV_ACCESS, PDV_CORTES } from '../CatalogList';
import API from "../../API";
import Catalog from '../Catalog';
import PdvCreate from "./PdvCreate";
import PdvZones from "./PdvZones";
import PdvCorte from "./PdvCorteList";
import PdvCorteDetails from "./PdvCorteDetails";

var PDVContainer = () => {
	var params = useParams<{ id: string }>();
	var { user, hasAccess } = useUser();
	var [pdvError, setPdvError] = useState<string>(null);
	var [pdv, setPDV] = useState<PDV>(null);

	useEffect(() => {
		if (!pdv && !Number.isNaN(parseInt(params.id))) {
			API.getPDV(parseInt(params.id)).then(res => {
				if (res.error) return setPdvError(res.message);
				setPDV(res.data);
			}).catch(err => {
				return setPdvError('Hubo un error inesperado cargando los datos del promotor (LCL-1)');
			});
		}
	}, []);

	if (Number.isNaN(parseInt(params.id))) {
		return <NotFound />
	}
	if (pdvError) {
		return <Header text="Error" subtext={pdvError} iconName='face-frown-open' />
	} else if (!pdv) {
		return <Header loading text="Cargando Punto de Venta" size={'small'} loaderSize="large" />
	}

	var onEditPdv = (pdv: PDV) => {
		setPDV(pdv);
	}

	return <SidebarContainer
		header={'Punto de venta'}
		routes={[
			{ url: `/pdv/${params.id}`, render: <PdvCreate key={'pdv-edit'} pdv={pdv} pdv_id={params.id}  onEditPdv={onEditPdv} /> },
			{ url: `/pdv/${params.id}/access`, render: <Catalog key={'pdv-access'} options={PDV_ACCESS(user, hasAccess, params.id)} /> },
			/* { url: `/pdv/${params.id}/cortes`, render: <Catalog key={'pdv-cortes'} options={PDV_CORTES(user, hasAccess, params.id)} /> }, */
			{ url: `/pdv/${params.id}/events`, component: PdvZones },
			{ url: `/pdv/${params.id}/cortes`, component: PdvCorte },
			{ url: `/pdv/${params.id}/cortes/:corte_id`, component: PdvCorteDetails },
		]}
		routeParams={{
			pdv,
			pdv_id: params.id,
			onEditPdv,
		}}
		getTitle={() => {
			return `PDV ${pdv.pdv_name}`
		}}
		items={[
			{ text: 'Detalles', icon: 'edit', url: `/pdv/${params.id}` },
			{ text: 'Cortes', icon: 'dollar', url: `/pdv/${params.id}/cortes` },
			{ text: 'Eventos', icon: 'theater masks', url: `/pdv/${params.id}/events` },
			{ text: 'Accesos', icon: 'key', url: `/pdv/${params.id}/access` },
		]}
		contentHeader={(<Header text={pdv.pdv_name} subtext={'Punto de venta'} style={{ marginTop: 0, paddingTop: 0 }} />)}
	/>
}

export default PDVContainer;